<script setup lang="ts">
import {inject, unref} from "vue";
import {storeToRefs} from "pinia";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import ClientGroupDropdown from "@/domains/administration/components/ClientGroupDropdown.vue";
import {useMainStore} from "@/stores/mainStore";
import {useUserStore} from "@/lib/stores/userStore";
import {useConnexionUser} from "@/lib/composables/useConnexionUser";
import useExportGenerateCSV from "@/composables/useExportGenerateCSV";
import {OpenSnackbarFunction} from "@/interfaces";

const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");

const {apiClient, clientsList} = storeToRefs(useMainStore());
const {isOplitAdmin, isGroupAdmin} = useUserStore();
const {updateUserClient} = useConnexionUser();
const {generateCSV} = useExportGenerateCSV();

async function exportCustomerInterfaceOfs() {
  const [error, ofsDetail] = await unref(
    apiClient,
  ).getCustomerInterfaceOfsDetails();
  if (error) return openSnackbar(null, null, error);

  generateCSV(ofsDetail, "Detail OFs");
}
</script>

<template>
  <ClientGroupDropdown
    v-if="isGroupAdmin || isOplitAdmin"
    :group-list="clientsList"
    @change="(id) => updateUserClient(id)"
  />
  <FButton outlined icon="share" @click="exportCustomerInterfaceOfs">
    {{ $t("scheduling.export_data") }}
  </FButton>
</template>
