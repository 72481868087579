<template>
  <div :class="wrapperClass">
    <div
      class="d-flex justify-space-between align-center fbody-1 force-body semi-bold text-newSubText"
    >
      <label v-if="label && !flushLabel" :class="computeLabelClass">
        {{ label }}
      </label>
      <span
        v-if="schedulingFilter && !!value.length && !hideClear"
        class="clear-filter cursor-pointer"
        :data-testid="`clear-filter-${filterId}`"
        @click="clear()"
      >
        {{ $t("global.clear") }}
      </span>
    </div>
    <template v-if="schedulingFilter">
      <div
        class="d-flex flex-wrap"
        :data-testid="`scheduling-filters-${filterId}`"
      >
        <template
          v-for="(item, idx) in value.slice(0, FILTERS_MAX_PREVIEWED_CHIPS)"
          :key="item[itemValue] ?? item"
        >
          <FChip
            :v-bind="item"
            :color="computedStatusChipColorBg(item)"
            :text-color="computedStatusChipColorText(item)"
            class="selected-chip px-2 my-2 mr-2"
            x-small
            close
            :data-testid="`scheduling-chip-${filterId}-${
              item[itemValue] ?? item
            }`"
            @close="() => deselectChip(idx)"
          >
            {{ chipContentText(item) }}
          </FChip>
        </template>
        <template v-for="(item, idx) in value" :key="item[itemValue] ?? item">
          <template v-if="idx >= FILTERS_MAX_PREVIEWED_CHIPS && !isCollapsed">
            <FChip
              :v-bind="item"
              class="selected-chip px-2 my-2 mr-2"
              :color="computedStatusChipColorBg(item)"
              :text-color="computedStatusChipColorText(item)"
              x-small
              close
              @close="() => deselectChip(idx)"
            >
              {{ chipContentText(item) }}
            </FChip>
          </template>
        </template>
        <template v-if="computedRemainingChipsCount > 0">
          <span
            v-if="isCollapsed"
            class="show-more-less align-self-center cursor-pointer"
            @click="expandCollapseRow"
          >
            {{ computedExpandButtonText }}
          </span>
          <span
            v-else
            class="show-more-less align-self-center cursor-pointer"
            @click="expandCollapseRow"
          >
            {{ $t("Home.see_less") }}
          </span>
        </template>
      </div>
    </template>

    <v-autocomplete
      :class="{
        autocomplete: true,
        'mt-2 scheduling-filters': schedulingFilter,
      }"
      :id="id"
      :ref="filterId"
      :model-value="value"
      :items="items"
      :item-title="itemText"
      :item-value="itemValue"
      :customFilter="customFilter"
      :label="flushLabel ? label : ''"
      :placeholder="placeholder"
      :multiple="multiple && (chips || schedulingFilter)"
      :closable-chips="chips"
      :disabled="disabled"
      :readonly="readonly"
      :suffix="suffix"
      :type="type"
      :return-object="returnObject"
      :search="textInput"
      :style="formComponentStyle"
      :menu-props="computedMenuProps"
      menu-icon=""
      variant="outlined"
      density="compact"
      hide-details
      persistent-hint
      autocomplete="off"
      :data-cy="dataCy"
      :data-testid="dataTestid"
      @update:model-value="onChange"
      @blur="() => onBlur()"
      @update:search="(v) => (textInput = v)"
      @keydown.left.stop="(e) => $emit('keydown', e)"
      @keydown.right.stop="(e) => $emit('keydown', e)"
    >
      <template v-slot:prepend-inner>
        <slot name="prepend-inner">
          <vue-feather
            v-if="icon"
            :type="icon"
            :size="iconSize"
            tag="div"
            class="autocomplete-icon"
            :stroke="iconStroke"
            :fill="iconFill"
          />
        </slot>
      </template>
      <template
        v-if="schedulingFilter && !!filteredItems.length"
        v-slot:prepend-item
      >
        <v-list-item>
          <v-list-item-title
            class="cursor-pointer"
            :data-testid="
              allSelected
                ? 'fautocomplete-list-item-deselect_all'
                : 'fautocomplete-list-item-select_all'
            "
            @mousedown="(e) => onMouseDown(e)"
          >
            {{
              allSelected ? $t("global.deselect_all") : $t("global.select_all")
            }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-if="schedulingFilter" v-slot:item="{props, item}">
        <v-list-item
          v-bind="props"
          title=""
          :data-testid="`fautocomplete-list-item-${item.title}`"
        >
          <template #prepend>
            <vue-feather
              size="14"
              tag="div"
              :type="
                itemIsSelected(value, item.raw) ? 'check-square' : 'square'
              "
              :stroke="
                itemIsSelected(value, item.raw)
                  ? variables.newPrimaryRegular
                  : variables.newSelected
              "
              class="align-self-center"
            />
          </template>

          <FChip
            class="px-2 ml-1"
            disabled
            :color="computedStatusChipColorBg(item.raw)"
            :text-color="computedStatusChipColorText(item.raw)"
            x-small
            :data-testid="`fautocomplete-list-chip-${item.title}`"
          >
            {{ getSchedulingItemText(item.raw) }}
          </FChip>
        </v-list-item>
      </template>
      <template v-else v-slot:item="{props, item}">
        <v-lazy v-bind="props" min-height="24px">
          <v-list-item
            :class="{
              'v-list-item--active': isSelectedItem(item.raw),
            }"
            class="dropdown-item"
            density="compact"
            link
          >
            <slot name="item" v-bind:item="item.raw">
              <template v-if="!itemsAreObjects">
                <div :data-cy="item.raw">
                  {{ item.raw }}
                </div>
              </template>

              <template v-else>
                <span :data-cy="item.raw[itemText]">
                  {{ item.raw[itemText] }}
                </span>
              </template>
            </slot>
          </v-list-item>
        </v-lazy>
      </template>

      <template v-if="schedulingFilter" v-slot:selection></template>
      <template v-else v-slot:chip="{item, props}">
        <slot v-if="chips" name="selection" v-bind="{item, props}">
          <FChip
            v-bind="props"
            close
            @close="() => $emit('delete-chip', item.raw)"
            :color="chipColor"
            :text-color="chipTextColor"
            data-cy="fautocomplete-chip"
            :show-tooltip="chipsTooltips"
            :tooltip-array="computedAggregatedTooltip"
          >
            {{ item.raw[itemText] }}
          </FChip>
        </slot>
        <slot v-else name="selection" v-bind:item="item.raw" />
      </template>
      <template v-slot:append-item>
        <slot name="append-item" v-bind:text="textInput" />
        <div v-if="schedulingFilter && !!filteredItems.length" class="validate">
          <FButton
            filled
            data-testid="fautocomplete-validate-button"
            @mousedown="(e) => onValidate(e)"
          >
            {{ $t("global.validate") }}
          </FButton>
        </div>
      </template>
      <template v-if="schedulingFilter" v-slot:no-data>
        <v-list-item>
          {{ $t("global.noData") }}
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref, unref} from "vue";
import {storeToRefs} from "pinia";

import moment from "moment";
import _ from "lodash";

import FChip from "@/components/Global/Homemade/Commons/FChip.vue";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import {
  getTagsTextColor,
  schedulingStatusColorBg,
  schedulingStatusColorText,
} from "@/tscript/utils/schedulingUtils";

import {FILTERS_MAX_PREVIEWED_CHIPS, OF_STATUS} from "@/config/constants";
import {
  getReadableImportParsingRuleValue,
  PENDING_ORDERS_PARAMS,
  SchedulingTag,
} from "@oplit/shared-module";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useFormComponents} from "@/composables/formComponents";

import {useMainStore} from "@/stores/mainStore";
import {useCustomerInterfaceStore} from "@/domains/customer-interface/stores/customerInterfaceStore";

export default defineComponent({
  name: "f-auto-complete",
  components: {FChip, FButton},
  props: {
    value: {
      default: () => [],
    },
    items: {
      type: Array as PropType<
        ({value: string | number; text: string} | string)[]
      >,
      default: () => [],
    },
    dataCy: String,
    dataTestid: String,
    id: {type: String, default: "f-autocomplete"},
    itemText: {type: String, default: "name"},
    itemValue: {type: String, default: "id"},
    icon: [String, Boolean],
    iconSize: {type: String, default: "24px"},
    iconStroke: String,
    iconFill: String,
    height: String,
    label: String,
    placeholder: String,
    suffix: String,
    type: {type: String, default: "text"},
    chipColor: {type: String, default: "newPrimaryRegular"},
    chipTextColor: {type: String, default: "blanc"},
    flushLabel: Boolean,
    large: Boolean,
    multiple: Boolean,
    chips: Boolean,
    chipsTooltips: Boolean,
    aggregatedTooltips: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    schedulingFilter: {type: Boolean, default: false},
    filterId: {type: String, default: ""},
    keepScrollbar: Boolean,
    returnObject: {type: Boolean, default: false},
    hideClear: {type: Boolean, default: false},
    wrapperClass: {type: String, default: ""},
  },
  emits: ["keydown", "delete-chip", "change"],
  setup(props) {
    const {schedulingTags} = storeToRefs(useSchedulingStore());
    const {formComponentStyle} = useFormComponents(props);
    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);
    const {customerInterfaceTags} = storeToRefs(useCustomerInterfaceStore());

    const textInput = ref("");
    const isCollapsed = ref(true);

    const itemsAreObjects = computed<boolean>(() => {
      return _.every(props.items, _.isObject);
    });

    const filteredItems = computed<string[]>(() => {
      if (!textInput.value) {
        return props.items.map((e) =>
          itemsAreObjects.value ? e[props.itemValue] : e,
        );
      }

      return props.items
        .filter((f) =>
          _.deburr(itemsAreObjects.value ? f[props.itemText] : f)
            .toLowerCase()
            .includes(_.deburr(textInput.value).toLowerCase()),
        )
        .map((e) => (itemsAreObjects.value ? e[props.itemValue] : e));
    });

    const allSelected = computed<boolean>(() => {
      return filteredItems.value.every((e) =>
        props.value.includes(_.get(e, props.itemValue, e)),
      );
    });

    function isSelectedItem(item: unknown) {
      if (!Array.isArray(props.value)) return false;
      return props.value.some(
        (selectedItem: unknown) =>
          selectedItem[props.itemValue] === item[props.itemValue],
      );
    }

    /**
     * returns a client scheduling tag from a string representing its id
     */
    function getSchedulingTag(tagID: string): SchedulingTag {
      return (
        unref(schedulingTags).find(({id}) => id === tagID) ||
        unref(customerInterfaceTags).find(({id}) => id === tagID)
      );
    }

    return {
      schedulingTags,
      customerInterfaceTags,
      textInput,
      isCollapsed,
      FILTERS_MAX_PREVIEWED_CHIPS: FILTERS_MAX_PREVIEWED_CHIPS,
      variables,
      formComponentStyle,
      itemsAreObjects,
      filteredItems,
      allSelected,
      isSelectedItem,
      getSchedulingTag,
    };
  },
  computed: {
    computedAggregatedTooltip: function (): any {
      const {value, items, itemText, aggregatedTooltips} = this;
      if (!aggregatedTooltips) return;
      return value.map(
        (val: any) =>
          (items.find((item: any) => item.id === val.id) || {})[itemText],
      );
    },
    computedMenuProps() {
      const {keepScrollbar, schedulingFilter} = this;
      return {
        contentClass: `${keepScrollbar ? "keep-scrollbar" : ""} ${
          schedulingFilter ? "scheduling-filter-dropdown mt-2" : ""
        }`,
      };
    },
    computeLabelClass() {
      const {schedulingFilter} = this;
      return schedulingFilter
        ? "fbody-1 force-body semi-bold text-newSubText"
        : "autocomplete-label";
    },
    computedRemainingChipsCount() {
      const {value: selectedItems} = this;
      return selectedItems.length - FILTERS_MAX_PREVIEWED_CHIPS;
    },
    computedExpandButtonText() {
      const {computedRemainingChipsCount} = this;
      return `+ ${computedRemainingChipsCount} ${this.$t(
        "global.other",
        computedRemainingChipsCount,
      )}`;
    },
  },
  methods: {
    /**
     * returns the text displayed for a filter object
     * for the `tags` type that are stored only with their ID in the database,
     * we apply a function to populate the full tag object to get its label
     */
    getSchedulingItemText(item: string | {text: string}): string {
      if (item === "none_value") return this.$t("global.none");
      const itemText: string = typeof item === "object" ? item.text : item;
      if (this.filterId !== "tags")
        return getReadableImportParsingRuleValue(itemText);
      return this.getSchedulingTag(itemText)?.label ?? itemText;
    },
    onChange(v: any): void {
      this.$emit("change", v);
      if (!this.schedulingFilter) this.textInput = "";
    },
    onMouseDown(e: Event): void {
      // this stops blur event from firing on prepend-item click
      e.preventDefault();
      const {allSelected, deselectAll, selectAll} = this;
      allSelected ? deselectAll() : selectAll();
    },
    onBlur(): void {
      if (this.schedulingFilter) this.textInput = "";
    },
    onValidate(e: Event): void {
      // this stops blur event from firing on prepend-item click
      e.preventDefault();
      this.$refs[this.filterId].isMenuActive = false;
      this.$refs[this.filterId].isFocused = false;
    },
    itemIsSelected(selectedFilter: Array<string>, item: any): boolean {
      return selectedFilter.includes(
        typeof item !== "object" ? item : item.value,
      );
    },
    computedStatusChipColorBg(item: {value: string} | string): string {
      if (item == null) return;
      let status = (item as {value: string}).value || (item as string);
      if (status == null) return;
      const {schedulingFilter} = this;
      if (this.filterId === "tags") {
        const schedulingTagColorCode =
          this.getSchedulingTag(status)?.color_name;
        if (schedulingTagColorCode)
          return this.variables[schedulingTagColorCode];
      }
      if (this.filterId === "op_status") {
        const isBaseStatus = Number.isNaN(Number(status));
        if (!isBaseStatus) status = OF_STATUS.PENDING;
      }
      const color = schedulingStatusColorBg(status, {schedulingFilter});
      return color;
    },
    computedStatusChipColorText(item: {value: string} | string): string {
      if (!item) return;
      const status = (item as {value: string}).value || (item as string);
      if (!status) return;
      const {schedulingFilter} = this;
      if (this.filterId === "tags") {
        const schedulingTagColorCode =
          this.getSchedulingTag(status)?.color_name;
        if (schedulingTagColorCode)
          return getTagsTextColor(schedulingTagColorCode);
      }

      const color = schedulingStatusColorText(status, {
        schedulingFilter,
      });
      return color;
    },
    expandCollapseRow() {
      const {isCollapsed} = this;
      this.isCollapsed = !isCollapsed;
    },
    deselectChip(chipIndex: number) {
      const {value: selectedItems} = this;

      this.$emit(
        "change",
        selectedItems.filter((_e: any, i: number) => i !== chipIndex),
      );
    },
    clear() {
      this.$emit("change", []);
    },
    deselectAll() {
      const {value: selectedItems, filteredItems} = this;
      this.$emit("change", _.without(selectedItems, ...filteredItems));
    },
    selectAll() {
      this.$emit("change", [
        ...new Set([...this.filteredItems, ...this.value]),
      ]);
    },
    weekFormatDate(date: string) {
      return moment(date).format(
        `DD/MM/YYYY - [${this.$t("global.week_short")}]WW`,
      );
    },
    chipContentText(item: string | {text: string} | number) {
      const {filterId} = this;
      const isBaseStatus = Number.isNaN(Number(item));
      if (item === "none_value") return this.$t("global.none");
      switch (filterId) {
        case "date":
          return this.weekFormatDate(item);
        case "op_status":
          if (isBaseStatus) return this.$t(`operation.status.${item}`);
          else if (item === PENDING_ORDERS_PARAMS.OTHERS) {
            return this.$t(`operation.status.operations_above`, {
              count: PENDING_ORDERS_PARAMS.COUNT_LIMIT,
            });
          } else return this.$t(`operation.status.operation`) + ` N-${item}`;
        case "has_component_shortage": {
          return this.$t(
            `FiltersDialog.option__has_component_shortage__${item}`,
          );
        }

        default:
          return this.getSchedulingItemText(item);
      }
    },
    customFilter(itemText: string, queryText: string, item: any) {
      //this normalizes special diacritical chars (e.g., é,â,ü) before filtering
      return (
        _.deburr(item?.text || itemText)
          .toLocaleLowerCase()
          .indexOf(_.deburr(queryText).toLocaleLowerCase()) > -1
      );
    },
  },
});
</script>

<style lang="scss">
@import "@/scss/mixins/keep-scrollbar";

div.v-application,
div.v-overlay-container {
  .v-list {
    background: rgb(var(--v-theme-newLayerBackground));
  }

  .autocomplete {
    border-radius: 8px;
    background: rgb(var(--v-theme-newSubBackground));
    outline: none;
    min-height: 40px;

    &-label {
      font-size: 16px;
      color: rgb(var(--v-theme-newSubText));
    }

    input {
      color: rgb(var(--v-theme-newSubText)) !important;
    }

    label {
      padding: 0;
    }

    .v-field__input {
      min-height: 40px !important;
      padding: 0 !important;

      & > input {
        align-self: center;
      }
    }

    .v-field__outline {
      border: 1px solid rgb(var(--v-theme-newSelected));
      border-radius: 8px;
      & [class*="v-field__outline__"] {
        border-width: 0px;
        &::before,
        &::after {
          border-width: 0px;
        }
      }
    }

    .v-text-field__suffix {
      color: rgb(var(--v-theme-newSubText));
    }

    .v-field__prepend-inner {
      margin-top: auto !important;
      margin-bottom: auto !important;
      margin-right: 4px;
      color: rgb(var(--v-theme-newSubText));
    }

    // States
    &:hover,
    & .v-field--focused {
      .v-field__prepend-inner {
        color: rgb(var(--v-theme-newMainText)) !important;
        color: red;
      }
    }

    &:hover {
      background: rgb(var(--v-theme-newHover));

      .v-field__outline {
        border: 1px solid rgb(var(--v-theme-newMainText)) !important;
      }
    }

    &:active,
    &:focus,
    & .v-field--focused {
      background: rgb(var(--v-theme-newLayerBackground));

      input {
        color: rgb(var(--v-theme-newMainText)) !important;
      }

      .v-text-field__suffix {
        color: rgb(var(--v-theme-newSubText));
      }
    }

    &:focus,
    & .v-field--focused {
      .v-field__outline {
        border: 1px solid rgb(var(--v-theme-newPrimaryRegular)) !important;
      }
    }

    &:active,
    & .v-field--focused {
      .v-field__outline {
        border: 1px solid rgb(var(--v-theme-newMainText)) !important;
      }
    }

    &:active,
    & .v-field--focused {
      .v-field__outline {
        border: 1px solid rgb(var(--v-theme-newMainText));
      }
    }
  }
  .scheduling-filter-dropdown {
    .v-list-item__icon {
      min-width: unset;
    }
    .v-list-item--link:before {
      background-color: unset;
    }
    .v-chip--disabled {
      opacity: unset;
    }
    .v-list-item__title {
      color: rgb(var(--v-theme-newPrimaryRegular));
      font-size: 12px;
      font-weight: 600;
    }
    .v-list--dense .v-list-item .v-list-item__icon {
      height: 14px;
    }
    .validate {
      position: sticky;
      bottom: -8px;
      display: flex;
      justify-content: flex-end;
      background: rgb(var(--v-theme-newLayerBackground));
      margin-bottom: -8px;
      padding: 8px; /* matches .v-list padding */
    }
    .v-list {
      @include keep-scrollbar();
    }
  }
  .scheduling-filters {
    background: transparent;
    .clear-filter {
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      color: rgb(var(--v-theme-newPrimaryRegular));
      &:hover {
        color: rgb(var(--v-theme-newPrimaryLight1));
      }
    }
    .show-more-less {
      font-size: 12px;
      font-style: italic;
      text-decoration: underline;
      font-weight: 600;
      line-height: 15px;
      color: rgb(var(--v-theme-newSubText));
      &:hover {
        opacity: 0.5;
      }
    }
    .selected-chip.v-chip .v-chip__close.v-icon {
      position: absolute;
      right: 3px;
      margin-right: 4px !important;
      margin-left: 4px;
      font-size: 10px !important;
    }
  }
}
</style>
