<template>
  <div
    class="operator-header fd-flex-center gap-2"
    :data-testid="`operator-header-${operateur.name}`"
  >
    <v-avatar class="icon-wrapper" :size="iconSize">
      <v-img v-if="operateur.avatar_url" :src="operator.avatar_url" cover />
      <div v-else :class="placeholderColor(operator)">
        {{ computeInitials(operator) }}
      </div>
    </v-avatar>

    <div class="operator-name--wrapper">
      <h4
        class="operator-name"
        :class="{
          'cursor-pointer': !disableClick,
          'text-newPrimaryRegular font-weight-bold': hasCompetency,
          'text-newPurpleDark1': is_mercateam_suggestion,
        }"
        :style="{'font-size': fontSize}"
        @click="() => selectOperator(operator)"
      >
        {{ operateur.name }}
      </h4>
      <div
        v-if="displayLegend && operateur.legend"
        class="operator-legend fcaption text-newDisableText"
      >
        {{ operateur.legend }}
      </div>
    </div>

    <ShiftTag
      v-if="!disableShift"
      :operator="operateur"
      :disable-change="disableChange"
      :machine="machine"
      :period="period"
    />

    <img v-if="hasCompetency" :src="getAssetURL('icons', 'award.svg')" />

    <DevHelper mini>({{ operateur.id }})</DevHelper>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue";
import {storeToRefs} from "pinia";
import ShiftTag from "@/domains/planning/domains/capacity-planning/components/ShiftTag.vue";
import {
  computeInitials,
  computePlaceholderColor,
  type CustomDateObject,
} from "@oplit/shared-module";
import {Machine, Operator} from "@/interfaces";
import {getAssetURL} from "@/tscript/utils/generalHelpers";
import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "operator-header",
  props: {
    operator: {
      type: Object as PropType<Operator>,
      default: () => ({} as Operator),
    },
    selectOperator: {type: Function, default: () => (): void => null},
    machine: {
      type: Object as PropType<Machine>,
      default: () => ({} as Machine),
    },
    period: {
      type: Object as PropType<CustomDateObject>,
    },
    disableClick: {type: Boolean, default: false},
    disableShift: {type: Boolean, default: false},
    disableChange: {type: Boolean, default: false},
    iconSize: {type: String, default: "24"},
    fontSize: {type: String, default: "16px"},
    displayLegend: {type: Boolean, default: false},
    is_mercateam_suggestion: {type: Boolean, default: false},
    hideCompetencyDisplay: {type: Boolean, default: false},
  },
  components: {
    ShiftTag,
  },
  setup(props) {
    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);
    const {hasOperatorCompetencyForMachine} = mainStore;

    const hasCompetency = computed(() => {
      if (props.hideCompetencyDisplay) return false;
      return hasOperatorCompetencyForMachine(props.operator, props.machine);
    });

    return {variables, hasCompetency, getAssetURL};
  },
  computed: {
    operateur(): Operator {
      const {operator} = this;
      return operator || {};
    },
  },
  methods: {
    computeInitials,
    computePlaceholderColor,
    placeholderColor(operator: Operator): string {
      return (
        "icon-placeholder cursor-pointer " +
        computePlaceholderColor(operator, this.variables)
      );
    },
  },
});
</script>

<style scoped lang="scss">
.operator-header {
  display: flex;
  align-items: center;
  color: rgb(var(--v-theme-newMainText));
  .v-image__image--cover {
    background-size: contain;
  }
}
.operator-name--wrapper {
  overflow: auto;
}
.operator-name,
.operator-legend {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.operator-legend {
  line-height: 1;
}
.status-chip {
  font-size: 16px;
  padding: 0 10px;
  height: 26px;
  flex: initial;
}
</style>
