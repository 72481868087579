import {defineStore, storeToRefs} from "pinia";
import {ref, computed, inject, watch} from "vue";
import _ from "lodash";
import moment from "moment";
import {useI18n} from "vue-i18n";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useMainStore} from "@/stores/mainStore";
import {useParametersStore} from "@/domains/parameters/stores/parametersStore";
import {useGanttStore} from "@/domains/scheduling/domains/gantt/stores/ganttStore";
import {
  isDoneOperation,
  isWorkDay,
  getNewDateFromShift,
  DATE_DEFAULT_FORMAT,
  type Calendar,
  type SectorCalendar,
} from "@oplit/shared-module";
import {
  DailyLoadRatesObject,
  SchedulingOperation,
  Sector,
  OpenSnackbarFunction,
} from "@/interfaces";

export const useSelectedOperations = defineStore("selectedOperations", () => {
  const {t} = useI18n();

  const {
    currentlyChangingOfIds,
    currentlySavingOfIds,
    selectedSimulation,
    savingPromisesIds,
    pgOpsModifications,
  } = storeToRefs(useSchedulingStore());
  const {getSectorsDailyLoadRatesObject} = useSchedulingStore();

  const {
    userData,
    clientParameters,
    stationsAndMachinesTagsGroupedById,
    apiClient,
  } = storeToRefs(useMainStore());
  const {getCtxSectorOrderedCalendars, getCtxNextAvailableDate} =
    useMainStore();
  const {doesClientHaveShiftPlanning, clientFirstShift} = storeToRefs(
    useParametersStore(),
  );
  const {ganttSectorCapacities, ganttIsPastDelayShown, ganttPeriod} =
    storeToRefs(useGanttStore());

  type PgSaveSchedulingEventsFirstParameter = Parameters<
    typeof apiClient.value.pgSaveSchedulingEvents
  >[0];
  type HandlePgUpdatePayload = {
    parameters: Omit<PgSaveSchedulingEventsFirstParameter, "data">;
    data: PgSaveSchedulingEventsFirstParameter["data"];
  }[];

  const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");

  const selectedOperations = ref<SchedulingOperation[]>([]);
  const selectableOperations = ref<SchedulingOperation[]>([]);
  /**
   * meant to be `watch`ed in components to perform app-only updates
   */
  const tempUpdatedSelectedOperations = ref<SchedulingOperation[]>([]);
  const hasOverlayOpened = ref(false);
  const isUpdating = ref(false);

  const selectedOperationsIDs = computed(() =>
    selectedOperations.value.map(({op_id}) => op_id),
  );
  const formattedSelectedOperationsForRetrocompatibility = computed<{
    [op_id: string]: SchedulingOperation;
  }>(() =>
    selectedOperations.value.reduce(
      (acc, operation) => ({
        ...acc,
        [operation.op_id]: operation,
      }),
      {},
    ),
  );
  const selectedOperationsWithBatchID = computed(() =>
    selectedOperations.value.filter(({batch_id}) => batch_id),
  );

  // FIXME: not ideal here as it is used in subcomponents (e.g. SchedulingOperationWrapper) as well
  watch(selectableOperations, (operations) => {
    if (selectedOperations.value.length === 0) return;

    selectedOperations.value = selectedOperations.value.map((operation) => {
      const match = operations.find(({op_id}) => operation.op_id === op_id);
      return match || operation;
    });
  });

  function toggleSelectedOperations(
    operations: SchedulingOperation[],
    options?: {operationKey: string},
  ) {
    if (!operations?.[0]) {
      selectedOperations.value = [];
      return;
    }

    const {operationKey = "secteur_id"} = options || {};

    const [operation] = operations;

    if (selectedOperations.value[0]?.[operationKey] != operation[operationKey])
      selectedOperations.value = [];

    if (
      operations.every(({op_id}) => selectedOperationsIDs.value.includes(op_id))
    ) {
      selectedOperations.value = selectedOperations.value.filter(
        ({op_id}) => !operations.find((operation) => operation.op_id === op_id),
      );
    } else selectedOperations.value.push(...operations);
  }
  function setSelectableOperations(operations: SchedulingOperation[]) {
    if (!operations?.length) selectableOperations.value = [];
    else {
      selectableOperations.value = operations.filter(
        ({op_id, batch_id}) =>
          !batch_id && !selectedOperationsIDs.value.includes(op_id),
      );
    }
  }
  function havePendingChangesOperations(operations: SchedulingOperation[]) {
    return operations.some(({of_id}) => currentlyChangingOfIds.value[of_id]);
  }
  function areSavingOperations(operations: SchedulingOperation[]) {
    return operations.some(({of_id}) => currentlySavingOfIds.value[of_id]);
  }
  function haveErrorsOnSaveOperations(operations: SchedulingOperation[]) {
    return operations.some(
      ({of_id}) => currentlySavingOfIds.value[of_id] === false,
    );
  }
  function isSelectedOperation({op_id}: {op_id?: string}): boolean {
    return selectedOperationsIDs.value.includes(op_id);
  }
  function isSelectedOperationsGroup(operations: {op_id?: string}[]): boolean {
    return operations.every(isSelectedOperation);
  }
  function getOperationsAggregatedQuantities(
    operations: SchedulingOperation[],
  ) {
    const defaultBatchId = "__no_batch__";

    const mappedOperations = operations.map(
      ({
        batch_id,
        quantite_of,
        op_duration,
        quantite_op,
        quantite,
        quantite_op_2,
      }) => ({
        batch_id,
        quantite_of: +quantite_of || 0,
        quantite_op: +quantite_op || +quantite || 0,
        op_duration: +op_duration || 0,
        quantite_op_2: +quantite_op_2 || 0,
      }),
    );

    const operationsByBatchId = _.groupBy(
      mappedOperations,
      ({batch_id}) => batch_id || defaultBatchId,
    );

    const {quantiteOf, quantiteOp, opDuration, quantiteOp2} = Object.entries(
      operationsByBatchId,
    ).reduce(
      (acc, [batch_id, ops]) => {
        if (batch_id === defaultBatchId) {
          for (const op of ops) {
            acc.quantiteOp += op.quantite_op;
            acc.opDuration += op.op_duration;
            acc.quantiteOp2 += op.quantite_op_2;
            acc.quantiteOf += op.quantite_of;
          }
        } else {
          acc.quantiteOp += _.maxBy(ops, "quantite_op").quantite_op;
          acc.opDuration += _.maxBy(ops, "op_duration").op_duration;
          acc.quantiteOp2 += _.sumBy(ops, "quantite_op_2");
          acc.quantiteOf += _.sumBy(ops, "quantite_of");
        }

        return acc;
      },
      {quantiteOf: 0, quantiteOp: 0, opDuration: 0, quantiteOp2: 0},
    );

    return {
      quantite_of: _.round(quantiteOf, 2),
      quantite_op: _.round(quantiteOp, 2),
      op_duration: _.round(opDuration, 2),
      quantite_op_2: _.round(quantiteOp2, 2),
    };
  }
  function getUniqueSelectedOperationsToUpdate(): SchedulingOperation[] {
    return _.uniqBy<SchedulingOperation>(
      selectedOperations.value.filter((op) => !isDoneOperation(op)),
      "of_id",
    );
  }
  function getUpdateByOperation(
    operation: SchedulingOperation,
    increment: number,
    parameters: {
      sector: Sector;
      isUpdatingToFuture: boolean;
      userId: string;
      sectorCalendars: Calendar[];
      isList?: boolean;
    },
  ) {
    const {new_date, day_date, op_dates = []} = operation || {};

    const {sector, isUpdatingToFuture, userId, sectorCalendars, isList} =
      parameters || {};

    // dates spanned by the operation
    const minOPDates = _.min(op_dates);
    const actualISODate = new_date ?? minOPDates ?? day_date;
    //handle horizontal move
    const actualDate = moment(actualISODate);
    const timestep = "days";
    // FIXME : this doesn't work when we move a multi day operation since this doesn't take closed days into account
    const dateIncrement = increment;
    let newDate: string | moment.Moment = actualDate.add(
      dateIncrement,
      timestep,
    );

    const isPastOperation =
      !isList &&
      ganttIsPastDelayShown.value &&
      newDate.isSameOrBefore(ganttPeriod.value[0]);

    if (isPastOperation && isUpdatingToFuture) newDate = moment();
    else if (!isWorkDay(newDate, sectorCalendars)) {
      /**
       * this logic is similarly applied on the back (see GNT-MOV-DTS)
       */
      newDate = getCtxNextAvailableDate(
        newDate as unknown as string,
        !isUpdatingToFuture,
        sector,
      );
    }

    const newDateISO = moment(newDate).format(DATE_DEFAULT_FORMAT);

    const update = {
      event_extra_infos: {updated_by: userId},
    };

    // FIXME: this condition is always true since we compare a moment object to a string
    if (actualISODate !== newDateISO)
      Object.assign(update, {new_date: newDateISO});

    return update;
  }
  function getUpdatedLocalDailyLoadRates({
    daily_load_rates,
    op,
    future_of_ids,
    new_date,
    first_day_duration,
    sectorCalendars,
  }: {
    daily_load_rates: DailyLoadRatesObject;
    op: SchedulingOperation;
    future_of_ids: string[];
    new_date: string;
    first_day_duration: number;
    sectorCalendars: Calendar[];
  }) {
    const dailyLoadRatesCopy = _.cloneDeep(daily_load_rates);

    const {of_id, day_date, op_duration, secteur_id} = op;

    Object.keys(dailyLoadRatesCopy).forEach((date) => {
      if (date < day_date) return;

      dailyLoadRatesCopy[date].mappedOpsWithDailyLoad = dailyLoadRatesCopy[
        date
      ].mappedOpsWithDailyLoad.filter(
        ({of_id: id}) => !future_of_ids.includes(id),
      );

      dailyLoadRatesCopy[date].load = _.sumBy(
        dailyLoadRatesCopy[date].mappedOpsWithDailyLoad,
        "load",
      );
    });

    let hasInsertedFirstDay = false;
    let remaining_load = op_duration;

    for (const [date, capaAndLoadRate] of Object.entries(dailyLoadRatesCopy)) {
      if (date < new_date || !isWorkDay(date, sectorCalendars)) continue;

      const {capa = ganttSectorCapacities.value[secteur_id]} = capaAndLoadRate;

      const loadToInsert = hasInsertedFirstDay
        ? Math.min(remaining_load, capa)
        : first_day_duration;

      dailyLoadRatesCopy[date].mappedOpsWithDailyLoad.push({
        of_id,
        load: loadToInsert,
      });

      dailyLoadRatesCopy[date].load += loadToInsert;
      remaining_load -= loadToInsert;
      if (!remaining_load) break;

      if (!hasInsertedFirstDay) hasInsertedFirstDay = !hasInsertedFirstDay;
    }

    return dailyLoadRatesCopy;
  }
  async function getAdjustLoadToCapaUpdate({
    operations,
    operation,
    update,
    sectorCalendars,
    dailyLoadRates,
    secteur_id,
    ofIds,
    remaining_capa,
    simulationId,
    canAdjustLoadToCapa,
    shouldAdjustLoadToCapa,
    isList,
  }: {
    operations: SchedulingOperation[];
    operation: SchedulingOperation;
    update: any;
    sectorCalendars: SectorCalendar[];
    dailyLoadRates: DailyLoadRatesObject;
    secteur_id: string;
    ofIds: string[];
    remaining_capa: number;
    simulationId: string;
    canAdjustLoadToCapa: boolean;
    shouldAdjustLoadToCapa: boolean;
    isList?: boolean;
  }): Promise<{
    remaining_capa: number;
    update: Record<string, unknown>;
    canAdjustLoadToCapa: boolean;
    dailyLoadRates: DailyLoadRatesObject;
  }> {
    let localRemainingCapa = remaining_capa;
    let localCanAdjustLoadToCapa = canAdjustLoadToCapa;

    const updateClone = Object.assign({}, update);

    if ("new_date" in updateClone) {
      if (
        !isList &&
        Object.keys(dailyLoadRates).includes(updateClone.new_date)
      ) {
        for (const [date, capaAndLoadRate] of Object.entries<{
          load: number;
          capa: number;
          mappedOpsWithDailyLoad: {of_id: string; load: number}[];
        }>(dailyLoadRates)) {
          if (date < updateClone.new_date) continue;
          const {
            load = 0,
            capa = ganttSectorCapacities.value[secteur_id],
            mappedOpsWithDailyLoad = [],
          } = capaAndLoadRate;

          const actualPlacedLoad =
            load -
            _.sumBy(
              mappedOpsWithDailyLoad.filter(({of_id}) => ofIds.includes(of_id)),
              ({load}) => load || 0,
            );

          localRemainingCapa = Math.max(
            +(capa - actualPlacedLoad).toFixed(2),
            0,
          );

          if (localRemainingCapa > 0 && isWorkDay(date, sectorCalendars)) {
            Object.assign(updateClone, {new_date: date});
            break;
          }

          localRemainingCapa = 0;
        }
      }

      if (localRemainingCapa === 0) {
        const [, result] = await apiClient.value.getNextAvailableCapaDay({
          simulation_id: simulationId,
          of_ids: ofIds,
          sector_id: secteur_id,
          new_date: updateClone.new_date,
          calendars: sectorCalendars,
          op_duration: operation.op_duration,
        });

        const {first_day_duration, new_date} = result;
        if (first_day_duration !== 0)
          Object.assign(updateClone, {first_day_duration, new_date});

        localCanAdjustLoadToCapa =
          shouldAdjustLoadToCapa && first_day_duration !== 0;
      } else {
        Object.assign(updateClone, {
          first_day_duration: Math.min(
            operation.op_duration,
            +localRemainingCapa.toFixed(2),
          ),
        });
      }
    }

    const currentOpIdx = operations.findIndex(
      (op) => op.of_id === operation.of_id,
    );
    //  update localDailyLoadRates for accurate future first_day_duration on multiselect move
    const localDailyLoadRates = getUpdatedLocalDailyLoadRates({
      daily_load_rates: dailyLoadRates,
      op: operation,
      future_of_ids: operations.slice(currentOpIdx).map((op) => op.of_id),
      new_date: update.new_date,
      first_day_duration: +remaining_capa.toFixed(2),
      sectorCalendars,
    });

    return {
      remaining_capa: localRemainingCapa,
      update: updateClone,
      canAdjustLoadToCapa: localCanAdjustLoadToCapa,
      dailyLoadRates: localDailyLoadRates,
    };
  }
  async function updateOperationsDates(
    increment: number,
    parameters: {
      daysArray?: string[];
      isPastDelayShown?: boolean;
      isGantt?: boolean;
      isList?: boolean;
    },
  ) {
    // prevent moving multiple times if an operation is ongoing
    if (isUpdating.value) return;
    if (selectedOperations.value.length === 0) return;

    const {has_gantt_load_adjustment} = clientParameters.value;
    const {id: userId} = userData.value || {};
    const {id: simulationId} = selectedSimulation.value;
    const {daysArray, isPastDelayShown, isGantt, isList} = parameters || {};

    const uniqSelectedOperations = getUniqueSelectedOperationsToUpdate();
    const isUpdatingToFuture = Math.sign(increment) === 1;
    const pgUpdatePayload: HandlePgUpdatePayload = [];

    let ofIds = _.uniq(uniqSelectedOperations.map(({of_id}) => of_id));
    let remaining_capa = 0;

    const {secteur_id} = selectedOperations.value[0] || {};
    const [sector] = stationsAndMachinesTagsGroupedById.value[secteur_id] || [];
    const sectorCalendars = getCtxSectorOrderedCalendars(sector);

    const shouldAdjustLoadToCapa =
      isGantt && has_gantt_load_adjustment && isUpdatingToFuture;

    let dailyLoadRatesObject = {};

    if (shouldAdjustLoadToCapa) {
      dailyLoadRatesObject = getSectorsDailyLoadRatesObject(
        [secteur_id],
        daysArray,
        // these need to be all the operations for the sector
        selectableOperations.value,
        {isPastDelayShown},
      );
    }

    for (const operation of uniqSelectedOperations) {
      const update = getUpdateByOperation(operation, increment, {
        isList,
        isUpdatingToFuture,
        userId,
        sectorCalendars,
        sector: sector,
      }) as Record<string, unknown>;

      let canAdjustLoadToCapa = shouldAdjustLoadToCapa;

      if (shouldAdjustLoadToCapa) {
        const adjustedValues = await getAdjustLoadToCapaUpdate({
          operations: uniqSelectedOperations,
          operation,
          update,
          sectorCalendars,
          dailyLoadRates: dailyLoadRatesObject,
          secteur_id,
          ofIds,
          remaining_capa,
          simulationId,
          canAdjustLoadToCapa,
          shouldAdjustLoadToCapa,
          isList,
        });

        canAdjustLoadToCapa = adjustedValues.canAdjustLoadToCapa;
        remaining_capa = adjustedValues.remaining_capa;
        dailyLoadRatesObject = adjustedValues.dailyLoadRates;
      }

      if (update.new_date && doesClientHaveShiftPlanning.value) {
        update.new_date = getNewDateFromShift(
          moment(update.new_date).format(DATE_DEFAULT_FORMAT),
          clientFirstShift.value,
        );
      }
      //we prepare the postgres parsing :
      const quickParse = true;
      if (quickParse) update.trigger_function = false;

      ofIds = ofIds.filter((ofId) => ofId !== operation.of_id);

      pgUpdatePayload.push({
        parameters: {
          has_gantt_load_adjustment: canAdjustLoadToCapa,
        },
        data: {
          update,
          initial: operation,
        },
      });
    }

    const originalSelectedOperations = _.cloneDeep(selectedOperations.value);

    const updatedSelectedOperations = selectedOperations.value.map(
      (operation) => {
        const match = pgUpdatePayload.find(
          ({data}) => data.initial.op_id === operation.op_id,
        );
        if (!match) return operation;
        return {
          ...operation,
          ...match.data.update,
        };
      },
    );

    // updating `tempUpdatedSelectedOperations` for app-only updates
    tempUpdatedSelectedOperations.value = [...updatedSelectedOperations];
    // updating `selectedOperations` for proper consecutive updates
    selectedOperations.value = [...updatedSelectedOperations];

    try {
      await handlePgUpdate(pgUpdatePayload);
    } catch (error) {
      openSnackbar(null, null, error);

      selectedOperations.value = originalSelectedOperations;
    }
  }
  async function _handlePgUpdate(
    pgUpdatePayload: HandlePgUpdatePayload,
  ): Promise<void> {
    const {client_id, id, name} = userData.value || {};
    const {id: simulationId} = selectedSimulation.value;

    isUpdating.value = true;

    const promiseId = `${new Date().getTime().toString()}`;
    // temporary trick to display the spinner in GlobalTabs
    savingPromisesIds.value[promiseId] = true;

    const updatedValues = [];

    for (const [strParameters, groupedData] of Object.entries(
      _.groupBy(pgUpdatePayload, ({parameters}) => JSON.stringify(parameters)),
    )) {
      const data = groupedData.map(({data}) => data);
      const parameters = JSON.parse(strParameters);

      const result = await apiClient.value.pgSaveSchedulingEvents({
        simulation: selectedSimulation.value,
        simulation_id: simulationId,
        data,
        userData: {client_id, id, name},
        unique_operations: true,
        should_handle_op_duration: true,
        has_shift_planning: doesClientHaveShiftPlanning.value,
        should_return_updated_values: true,
        ...parameters,
      });

      if (result.updated_values?.length > 0)
        updatedValues.push(...result.updated_values);
    }

    if (updatedValues.length > 0) {
      pgOpsModifications.value = updatedValues.filter(({op_id}) =>
        selectedOperationsIDs.value.includes(op_id),
      );
    }

    delete savingPromisesIds.value[promiseId];

    isUpdating.value = false;
  }
  const handlePgUpdate = _.debounce(_handlePgUpdate, 1500);
  /**
   * return the state of disability or availability for arrows of OperationsActionsMenu
   * as well as the justificating tooltips in case of disablance
   */
  function getArrowsState(
    selectedArray: string[],
    opsArray: SchedulingOperation[],
    isPastCurrentOperation = false,
  ): {
    state?: {
      Up?: boolean;
      Down?: boolean;
      Left?: boolean;
    };
    tooltips?: {
      Up?: string | null;
      Down?: string | null;
      Left?: string | null;
    };
  } {
    if (isPastCurrentOperation) {
      return {
        state: {
          Up: false,
          Down: false,
          Left: false,
        },
      };
    }
    if (!selectedArray?.length || !opsArray?.length) return {};

    const isAnyNotSelected = (array: SchedulingOperation[]): boolean =>
      array.some((item) => !selectedArray.includes(item.op_id));

    /**
     * On a given maille, ops are ordered by day_date but op_order depends on lower maille
     * We avoid ops to be moved outside of their given day_date
     */
    const getAnyFirstOrLastOfLowerMaille = (
      selectedArray: string[],
      opsArray: SchedulingOperation[],
      {first}: {first: boolean},
    ) => {
      if (!selectedArray?.length || !opsArray?.length) return false;
      return selectedArray.some((opId) => {
        const index = opsArray.findIndex((op) => op.op_id === opId);
        if (index === -1) return false;
        if (first) {
          if (index === 0) return true;
          const previousOp = opsArray[index - 1];
          return previousOp.day_date !== opsArray[index].day_date;
        } else {
          if (index === opsArray.length - 1) return true;
          const nextOp = opsArray[index + 1];
          return nextOp.day_date !== opsArray[index].day_date;
        }
      });
    };

    const isAnyFirstOpOfLowerMaille = getAnyFirstOrLastOfLowerMaille(
      selectedArray,
      opsArray,
      {first: true},
    );

    const isAnyLastOpOfLowerMaille = getAnyFirstOrLastOfLowerMaille(
      selectedArray,
      opsArray,
      {first: false},
    );

    const tooltipUp = !isAnyNotSelected(opsArray.slice(0, selectedArray.length))
      ? t("useSelectedOperations.tooltips__first_selected")
      : isAnyFirstOpOfLowerMaille
      ? t("useSelectedOperations.tooltips__previous_different_date")
      : null;

    const tooltipDown = !isAnyNotSelected(
      opsArray.slice(selectedArray.length * -1),
    )
      ? t("useSelectedOperations.tooltips__last_selected")
      : isAnyLastOpOfLowerMaille
      ? t("useSelectedOperations.tooltips__next_different_date")
      : null;

    const state = {
      Up: !tooltipUp,
      Down: !tooltipDown,
    };

    return {state, tooltips: {Up: tooltipUp, Down: tooltipDown}};
  }

  return {
    selectedOperations,
    selectableOperations,
    toggleSelectedOperations,
    setSelectableOperations,
    formattedSelectedOperationsForRetrocompatibility,
    havePendingChangesOperations,
    areSavingOperations,
    haveErrorsOnSaveOperations,
    selectedOperationsIDs,
    hasOverlayOpened,
    isSelectedOperation,
    isSelectedOperationsGroup,
    getOperationsAggregatedQuantities,
    selectedOperationsWithBatchID,
    updateOperationsDates,
    tempUpdatedSelectedOperations,
    isUpdating,
    getArrowsState,
  };
});
