import {computed, h, inject, ref} from "vue";
import {defineStore, storeToRefs} from "pinia";
// intended for tests to pass
import {i18n} from "@/i18n";
import moment from "moment";
import {onSnapshot, serverTimestamp} from "firebase/firestore";
import _ from "lodash";

import {useMainStore} from "./mainStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {usePermissionsStore} from "@/lib/stores/permissionsStore";

import {
  DATE_DEFAULT_FORMAT,
  SIMULATION_STATUS,
  EVENT_STATUS,
} from "@/config/constants";
import loggerHelper from "@/tscript/loggerHelper";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {saveEventInFirestore} from "@/tscript/utils/eventSaver";
import {prepareImportPromises} from "@/views/Simulation/simulationMixins/importHelper";
import {useRoute, useRouter} from "vue-router";
import {handleRouteQueryReplace} from "@/router/utils";
import type {
  GenericFunction,
  GenericObject,
  OpenDialogFunction,
  OpenSnackbarFunction,
  OplitEvent,
  Sector,
  Segment,
  Simulation,
  Team,
} from "@/interfaces";
import {getArchivedSimulationName} from "@/tscript/utils/schedulingUtils";
import {fetchActiveMSD} from "@/tscript/utils/sharedModuleHelper";
import {getSegmentPayload} from "@/tscript/utils/segmentHelper";
import {apiClient} from "@/tscript/utils/requirements";
import {isArchivedSimulation} from "@/tscript/utils/simulation";
import {parseDate} from "@oplit/shared-module";
import {useUserStore} from "@/lib/stores/userStore";
import {oplitClient} from "@/api";
import {useParametersUtils} from "@/domains/parameters/composables/useParametersUtils";
import FCheck from "@/components/Global/Homemade/Controls/FCheck.vue";

interface ArchiveSimulationPayload {
  simulation: Simulation;
  confirm?: boolean;
  sendPlanningToMercateam?: boolean;
  // used for segment tracking only
  is_from_menu?: boolean;
}

export const useSimulationStore = defineStore("simulation", () => {
  const openDialog = inject<OpenDialogFunction>("openDialog");
  const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");
  const segment = inject<Segment>("segment");
  const mainStore = useMainStore();
  const router = useRouter();
  const route = useRoute();
  const {t} = i18n;
  const {selectedSimulation, isPiloting, schedulingSimulations} = storeToRefs(
    useSchedulingStore(),
  );
  const {loadSchedulingSimulations} = useSchedulingStore();

  const simulationStep = ref<number>(1);
  const simulationBtnClicked = ref<number | null>(null);
  const isAsyncOperationRunning = ref<boolean>(false);
  const isCreatingSimulation = ref(false);
  const comparedAgainstSimulations = ref<Simulation[]>([]);
  const operatorPlanningLoadings = ref<Record<string, boolean>>({});
  const sectorWLoadFormula = ref<string[]>([]);

  const isOperatorPlanningLoading = computed<boolean>(() =>
    Object.values(operatorPlanningLoadings.value).some(Boolean),
  );

  // logic for events of customers with SSE enabled
  async function saveSSEAndListen(parameters: {
    data: any;
    dataType: string;
    routeName?: string;
    noListening?: boolean;
    sectorTree?: Sector;
    extraParams?: {
      oldEvent?: GenericObject;
      shouldRecalculateCapaOnDeletion?: boolean;
      [key: string]: any;
    };
    callback?: GenericFunction;
    closingCallback?: GenericFunction;
    eventMigrationParams?: object;
  }) {
    const {apiClient, activePerim, disablePgRefresh} = storeToRefs(mainStore);

    const {
      data,
      dataType = EVENT_STATUS.ADDED,
      routeName = "Event",
      noListening = false,
      extraParams,
      sectorTree = activePerim.value,
      callback = () => void 0,
      closingCallback = () => void 0,
    } = parameters;

    return new Promise(async (resolve) => {
      disablePgRefresh.value = true;

      const evsource = await apiClient.value[`post${routeName}ChangeAndListen`](
        data,
        dataType,
        extraParams,
      );

      if (noListening) {
        evsource.stream();
        return resolve(true);
      }

      evsource.addEventListener("message", async (e: any) => {
        if (!e?.data?.length) return;
        const {type, data} = JSON.parse(e.data);
        if (type === "done") disablePgRefresh.value = false;
        const shouldResolve = await callback(type, data, sectorTree);
        if (shouldResolve) resolve(true);
      });
      evsource.addEventListener("error", (e: any) => {
        loggerHelper.log("error", e);
        evsource.close();
        disablePgRefresh.value = false;
        resolve(false);
      });
      evsource.addEventListener("open", () => {
        loggerHelper.log("SSE connection open");
      });
      evsource.addEventListener("readystatechange", (d: any) => {
        if (d.readyState !== EventSource.CLOSED) return;
        loggerHelper.log("SSE connection closed");
        evsource.close();
        disablePgRefresh.value = false;
        closingCallback();
        resolve(true);
      });

      evsource.stream();
    });
  }
  // TODO: harmonize logics with eponymous method in eventsStore + remove from simulationStore
  async function deleteEvent(parameters) {
    const {event, force_status}: {event: OplitEvent; force_status: string} =
      parameters;
    if (!event?.id) return false;

    const {
      pgSse,
      arePDPSimulationUpdatesDisabled,
      userData,
      disablePgRefresh,
      pgRefresh,
    } = storeToRefs(mainStore);
    const {first_name, last_name, id: user_id} = userData.value;

    if (arePDPSimulationUpdatesDisabled.value && !event.is_simple_event)
      return false;

    const status = force_status || EVENT_STATUS.REMOVED;
    const use_sse =
      (pgSse.value.includes("charge") && event?.type === "charge") ||
      (pgSse.value.includes("capa") && event?.type === "capa") ||
      (pgSse.value.includes("planning") && event.is_planning_event);
    const update: any = {
      status,
      updated_at: serverTimestamp(),
      updated_by: {first_name, last_name, user_id},
    };
    const macro_status =
      status === EVENT_STATUS.ACTIVE
        ? EVENT_STATUS.ADDED
        : EVENT_STATUS.REMOVED;

    const fullEvent = {...event, ...update};
    const error = await saveEventInFirestore(
      {id: event.id, ...update},
      {
        use_event_sse: use_sse,
        disable_pg_refresh: disablePgRefresh.value,
        fullEvent,
        storeCommitCallback: () => (disablePgRefresh.value = false),
        sseCallback: () =>
          saveSSEAndListen({
            data: fullEvent,
            dataType: macro_status,
            callback: function (...args: any) {
              const [type, data] = args;
              return defaultSSECallback(type, data);
            },
          }),
      },
    );

    if (error?.e) openDialog(null, null, error.e);
    else if (status === EVENT_STATUS.DISABLED) {
      openSnackbar(
        {
          message: t("Alert.disable_success"),
        },
        "DELETE_SUCCESS",
      );

      segment.value.track(
        "[PDP] Event Disabled",
        getSegmentPayload("event", fullEvent),
      );
    } else if (status !== EVENT_STATUS.ACTIVE) {
      openSnackbar(null, "DELETE_SUCCESS");

      segment.value.track(
        "[PDP] Event Deleted",
        getSegmentPayload("event", fullEvent),
      );
    } else openSnackbar(null, "SAVE_SUCCESS");

    /**
     * was introduced to refresh the planning data upon deleting an event
     */
    pgRefresh.value++;

    return true;
  }
  // default callback used for SSE
  // the `pg_trigger` store variable is watched by components to have specific effects upon completion of this action
  function defaultSSECallback(
    type: string,
    data: GenericObject,
    sectorTree?: Record<string, any>,
  ) {
    const {activePerim, pgTrigger} = storeToRefs(mainStore);
    const sector = sectorTree ?? activePerim.value;
    const {step, secteur_id, diffuse} = data || {};
    loggerHelper.log(data);

    if (
      type === "postgres" &&
      ((step === "daily_load" && secteur_id === sector.id) ||
        step === "daily_capa") //we need to emit the update for all sectors when it comes to capa
    ) {
      //for capa triggers we let the sector cards decide what is of interest for them
      loggerHelper.log(step, "received in SSE for sector", secteur_id);
      pgTrigger.value = {type: step, secteur_id};

      return secteur_id === sector.id;
    } else if (diffuse) pgTrigger.value = data;
  }

  async function getUpdatedSimulation({
    simulation,
    status,
    sendPlanningToMercateam,
  }: {
    simulation: Simulation;
    status: string;
    sendPlanningToMercateam: boolean;
  }): Promise<Partial<Simulation>> {
    const {userData, team} = storeToRefs(mainStore);
    const {
      id: user_id,
      first_name = "",
      last_name = "",
      client_id,
    } = userData.value;

    const {import_ids} = await prepareImportPromises(
      client_id,
      true,
      simulation, // {} || //make sure that this line is always active (I sometime comment it for tests)
      team.value,
    );

    const updatedSimulation: Partial<Simulation> = {
      updated_at: serverTimestamp(),
      updated_by: {user_id, first_name, last_name},
      status,
      import_ids,
      ...(sendPlanningToMercateam && {merca_synced: true}),
    };

    if (!simulation.msd_id) {
      const activeMSD = await fetchActiveMSD(simulation, client_id);
      if (activeMSD?.id) updatedSimulation.msd_id = activeMSD.id;
    }
    if (status === "archived") {
      updatedSimulation.archived_at = serverTimestamp();
      if (!simulation.forceToday) {
        updatedSimulation.forceToday = moment().format("YYYY-MM-DD");
        updatedSimulation.dateMin = moment().format("YYYY-MM-DD");
      }
    }

    return updatedSimulation;
  }
  async function savePlanningToMercateam(
    simulation: Simulation,
  ): Promise<boolean> {
    const {userData, perimeters, apiClient, hasMercateam, highestSector} =
      storeToRefs(mainStore);
    const {client_id} = userData.value;
    if (!hasMercateam.value) return;

    const params = {
      sector_tree: highestSector,
      client_id,
      simulation,
      startDate: moment(simulation.forceToday).format(DATE_DEFAULT_FORMAT),
      endDate: moment(simulation.forceToday)
        .add(6, "months")
        .format(DATE_DEFAULT_FORMAT),
      perimetres: perimeters.value,
    };

    try {
      return await apiClient.value.saveMercateamPlannings(params);
    } catch (e) {
      loggerHelper.log(e);
      return false;
    }
  }
  async function archiveSimulation(
    parameters: ArchiveSimulationPayload,
  ): Promise<void> {
    const {simulation: storedSimulation, activeSector} = storeToRefs(mainStore);
    const {
      simulation = storedSimulation.value,
      confirm = false,
      sendPlanningToMercateam = false,
      is_from_menu = false,
    } = parameters || {};

    if (!confirm) askBeforeArchivingSimulation(parameters);
    else {
      const dialogVariable = await enforceSimulationOperations(simulation);
      if (dialogVariable) return openDialog(null, dialogVariable);

      isAsyncOperationRunning.value = true;

      const updatedSimulation = await getUpdatedSimulation({
        simulation,
        status: SIMULATION_STATUS.ARCHIVED,
        sendPlanningToMercateam,
      });

      const error: any = await dbHelper.setDataToCollection(
        "simulations",
        simulation.id,
        updatedSimulation,
        true,
      );

      if (!error?.e) {
        onSuccessfulStatusUpdate({
          ...simulation,
          status: updatedSimulation.status,
          name: getArchivedSimulationName(simulation),
        });

        const mercaSaveSuccess =
          sendPlanningToMercateam &&
          (await savePlanningToMercateam({
            ...simulation,
            ...updatedSimulation,
          }));

        openSnackbar({
          type: "positive",
          message: `${t("Alert.archive_success", {
            sector: activeSector.value?.site_name,
          })}. ${mercaSaveSuccess ? t("Alert.merca_save_success") : ""}`,
        });

        segment.value.track(
          `[${simulation.is_scheduling ? "ORDO" : "PDP"}] Simulation Validated`,
          {
            ...getSegmentPayload("simulation", simulation),
            is_from_menu,
          },
        );
      }

      isAsyncOperationRunning.value = false;
    }
  }
  async function deleteSimulation(parameters): Promise<void> {
    const {simulation: storedSimulation, activeSector} = storeToRefs(mainStore);
    const {
      simulation = storedSimulation.value,
      confirm = false,
    }: {
      simulation: Simulation;
      confirm: boolean;
    } = parameters || {};

    if (!confirm) {
      // ask for confirmation before deleting
      openDialog({
        type: "warning",
        action: function () {
          deleteSimulation({simulation, confirm: true});
        },
        message: `
          ${t("Simulation.about_to_delete", {
            simu_name: simulation.name,
          })}
          "${activeSector.value.site_name || ""}".
          ${t("Simulation.unmodifiable_delete")}
          ${t("Simulation.confirm_delete")}
        `,
      });
    } else {
      const dialogVariable = await enforceSimulationOperations(simulation);
      if (dialogVariable) return openDialog(null, dialogVariable);

      isAsyncOperationRunning.value = true;

      const updatedSimulation = await getUpdatedSimulation({
        simulation,
        status: SIMULATION_STATUS.REMOVED,
        sendPlanningToMercateam: false,
      });

      const error: any = await dbHelper.setDataToCollection(
        "simulations",
        simulation.id,
        updatedSimulation,
        true,
      );
      if (!error?.e) {
        onSuccessfulStatusUpdate({
          ...simulation,
          status: updatedSimulation.status,
        });
      }

      isAsyncOperationRunning.value = false;
    }
  }
  async function updateSimulationFromScheduling() {
    openSnackbar({loading: true});
    const {simulation, pgRefresh} = storeToRefs(mainStore);
    const {success} = await apiClient.postRequest(
      "/api/simulations/update-load-from-scheduling",
      {
        simulation: simulation.value,
      },
    );
    openSnackbar({loading: false});
    if (success) {
      openSnackbar({
        type: "positive",
        message: t("Alert.simulation_ordo_update_success"),
      });
    } else {
      openSnackbar({
        type: "negative",
        message: t("Alert.Prefixes.error"),
      });
    }
    pgRefresh.value++;
  }
  async function updateSimulation(
    simulation_id: string,
    payload: Partial<Pick<Simulation, "selected_period">>,
  ): Promise<[null, null] | [null, Error]> {
    const {userData} = storeToRefs(mainStore);

    const err = await dbHelper.setDataToCollection(
      "simulations",
      simulation_id,
      payload,
      true,
    );
    await loadSimulations(userData.value.client_id);
    if (err) return [null, err.e];

    return [null, null];
  }
  function enforceSimulationOperations(simulation: Simulation): string | void {
    const {userData} = storeToRefs(mainStore);
    const {id: user_id, client_id, role} = userData.value;
    if (!client_id || !user_id) return "NO_CLIENT_ID";
    if (
      !["ADMIN", "CLIENT_SUPER_ADMIN", "CLIENT_ADMIN", "GROUP_ADMIN"].includes(
        role,
      )
    )
      return "NO_PERMISSION";
    if (!simulation?.id) return "NO_SIMULATION";

    return;
  }
  async function onSuccessfulStatusUpdate(
    simulation: Simulation,
  ): Promise<void> {
    const {teamSimulations} = mainStore;
    const {userData, isScheduling} = storeToRefs(mainStore);
    let simulationsList = [];
    if (isScheduling.value) {
      await loadSchedulingSimulations(userData.value.client_id);
      simulationsList = schedulingSimulations.value;
    } else {
      await loadSimulations(userData.value.client_id);
      simulationsList = teamSimulations;
    }
    setSimulationGlobal(
      simulation.status === "removed" ? simulationsList[0] : simulation,
    );
  }
  function getNextRouteName() {
    const {isScheduling} = storeToRefs(mainStore);
    if (!isScheduling.value) return route.name;
    return isPiloting.value ? "scheduling-piloting" : "scheduling-planning";
  }
  function setSimulationGlobal(simulation: Simulation) {
    const {isScheduling, simulation: storedSimulation} = storeToRefs(mainStore);
    if (isScheduling.value) selectedSimulation.value = simulation;
    else storedSimulation.value = simulation;

    // changes url param upon creation/deletion/context (team) change
    const newQuery = handleRouteQueryReplace(
      route.query,
      "simulation_id",
      // we sometimes clear the simulation, therefore using the conditional chaining
      simulation?.id,
    );
    const newRouteName = getNextRouteName();

    if (!newQuery && newRouteName === route.name) return;

    const routerFunction = newRouteName === route.name ? "replace" : "push";
    router[routerFunction]({
      name: newRouteName,
      // we fallback on query in case we're only changing the route name (e.g. validating a simulation in scheduling)
      query: newQuery || route.query,
    });
  }
  function askBeforeArchivingSimulation(parameters: ArchiveSimulationPayload) {
    // ask for confirmation before archiving
    const {
      simulation: storedSimulation,
      activeSector,
      hasMercateam,
      teams,
      isScheduling,
    } = storeToRefs(mainStore);
    const {simulation = storedSimulation.value} = parameters || {};

    const message = [
      t("Simulation.unmodifiable_simulation"),
      t("Simulation.confirm_archive"),
    ];

    if (isScheduling.value) {
      message.unshift(
        t("scheduling.about_to_archive"),
        `"${simulation.name}".`,
      );
    } else {
      const teamSimulations: Team[] = simulation.team_ids?.length
        ? teams.value.filter((t: Team) => simulation.team_ids.includes(t.id))
        : [];

      message.unshift(
        t("Simulation.about_to_archive"),
        `"${
          teamSimulations?.map((team) => team.secteur_name).join(", ") ||
          activeSector.value.site_name ||
          ""
        }".`,
      );
    }

    const sendPlanningToMercateam = ref(false);

    const dialogArguments: Parameters<typeof openDialog> = [
      {
        type: "warning",
        action: function () {
          // only used for PDP simulation

          archiveSimulation({
            simulation,
            confirm: true,
            sendPlanningToMercateam:
              hasMercateam.value && sendPlanningToMercateam.value,
          });
        },
        message: message.join(" "),
      },
    ];

    if (hasMercateam.value) {
      dialogArguments.push(null, null, {
        "additional-body": () => {
          return h(FCheck, {
            label: t("Mercateam.send_to"),
            modelValue: sendPlanningToMercateam.value,
            "onUpdate:modelValue": (bool: boolean) =>
              (sendPlanningToMercateam.value = bool),
          });
        },
      });
    }

    openDialog(...dialogArguments);
  }

  async function loadSimulations(clientId: string, forceAsAdmin = false) {
    const {simulations, isScheduling, simulation} = storeToRefs(mainStore);
    const {addUnsubscribe} = mainStore;
    const {isOplitAdmin} = storeToRefs(useUserStore());
    let simulationResults = [];
    let idx = 0;

    const queryRef = dbHelper.createRef("simulations", {
      where: [{field: "client_id", value: clientId, compare: "=="}],
      orderBy: [{field: "created_at", direction: "desc"}],
    });
    const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data() || {};
        if ([data.status, change.type].includes("removed")) {
          simulationResults = simulationResults.filter(
            (x: any) => x.id !== data.id,
          );
        } else if (data.is_scheduling) {
          //do nothing
        } else if (data.only_admin && !(isOplitAdmin.value || forceAsAdmin)) {
          //do nothing
        } else if (change.type == "added") simulationResults.push(data);
        else {
          simulationResults = simulationResults.map((x: any) =>
            x.id === data.id ? data : x,
          );
        }
      });

      simulationResults = _.orderBy(
        simulationResults,
        (o) => parseDate(o.created_at),
        "desc",
      );
      simulations.value = simulationResults;

      idx++;
      if (idx === 1) {
        if (isScheduling.value) return;

        simulation.value = viewableSimulationFromRouteOrPermissions.value || {};
      }
    });

    addUnsubscribe(unsubscribe);
  }
  const viewableSimulationFromRouteOrPermissions = computed(() => {
    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {teamSchedulingSimulations} = storeToRefs(useSchedulingStore());
    const {isScheduling, teamSimulations} = storeToRefs(mainStore);

    // previously-used code to select the simulation matching the query id
    const simulationId = route?.query?.simulation_id;
    const contextualSimulations = isScheduling.value
      ? teamSchedulingSimulations.value
      : teamSimulations.value;
    const orderedContextualSimulations = _.orderBy(
      contextualSimulations,
      (o) => parseDate(o.created_at),
      "desc",
    );
    const simulation: Simulation =
      contextualSimulations.find(
        (sim: Simulation) => !simulationId || sim.id === simulationId,
      ) || orderedContextualSimulations.at(0);
    let viewableSimulation = simulation;
    if (
      !currentPermissions.value.general.read_simulation_active &&
      !isArchivedSimulation(simulation)
    ) {
      const orderedArchivedSimulations =
        contextualSimulations.filter(isArchivedSimulation);
      viewableSimulation = orderedArchivedSimulations[0] || null;
    }

    return viewableSimulation;
  });

  async function getSectorWLoadFormula() {
    const {featureFlags} = useParametersUtils();

    sectorWLoadFormula.value = [];
    if (featureFlags.value.use_formula_for_pdp_load) {
      const [ids, error] =
        await oplitClient.apiSimulationsGetSectorWLoadFormula();

      if (!error) sectorWLoadFormula.value = ids;
    }
  }

  return {
    simulationStep,
    simulationBtnClicked,
    isAsyncOperationRunning,
    saveSSEAndListen,
    deleteEvent,
    defaultSSECallback,
    getUpdatedSimulation,
    archiveSimulation,
    deleteSimulation,
    updateSimulation,
    updateSimulationFromScheduling,
    setSimulationGlobal,
    loadSimulations,
    viewableSimulationFromRouteOrPermissions,
    isCreatingSimulation,
    comparedAgainstSimulations,
    operatorPlanningLoadings,
    isOperatorPlanningLoading,
    sectorWLoadFormula,
    getSectorWLoadFormula,
  };
});
