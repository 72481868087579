<script setup lang="ts">
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";
import CapaTooltip from "@/components/Scheduling/Capacity/CapaTooltip.vue";
import {useMainStore} from "@/stores/mainStore";
import {useGanttStore} from "@/domains/scheduling/domains/gantt/stores/ganttStore";
import {CSS_SCHEDULING_GANTT_CELL_CLASS} from "@/config/constants";
import type {DailyLoadRatesObject} from "@/interfaces";

interface GanttRowHeaderProps {
  dailyLoadRates: DailyLoadRatesObject;
  hideDailyLoadRate?: boolean;
}

const props = defineProps<GanttRowHeaderProps>();

const {t} = useI18n();

const {ganttDaysArray} = storeToRefs(useGanttStore());
const {getDailyColorCssVar} = useGanttStore();
const {variables} = storeToRefs(useMainStore());

function isLastDisplayedDay(day: string): boolean {
  return day === ganttDaysArray.value[ganttDaysArray.value.length - 1];
}
/**
 * TODO:
 * @returns a numeric value representing the daily load rate for the corresponding day
 */
function getDailyLoadRate(day: string): string {
  const {value, load, capa} = props.dailyLoadRates[day] || {};
  if (capa === undefined) return t("global.closed");
  if (load && !capa) return t("global.infinity");
  return `${value || 0}%`;
}
/**
 * @returns an array of classes for the daily load sub-cells
 */
function getDailyLoadRateStyle(day: string): Record<string, string> {
  const {value, load, capa} = props.dailyLoadRates[day] || {};

  if (capa !== undefined && load && +value > 100) {
    /** overloaded */
    return {
      backgroundColor: "rgb(var(--v-theme-red-100))",
      color: "rgb(var(--v-theme-red-800))",
    };
  }

  const colorName =
    capa === undefined
      ? "newLightGrey" /* closed day */
      : "newLayerBackground"; /* default day */

  return {
    backgroundColor: `var(${getDailyColorCssVar(day)}, ${
      variables.value[colorName]
    })`,
  };
}
</script>

<template>
  <div class="gantt-row-header--wrapper">
    <div class="gantt-header text-14">
      <div class="gantt-header--days-wrapper">
        <div
          v-for="day in ganttDaysArray"
          :key="`day-${day}`"
          :class="[
            {
              'is-last-displayed-day': isLastDisplayedDay(day),
            },
            'gantt-header--day-wrapper',
          ]"
        >
          <div
            v-if="!hideDailyLoadRate"
            :class="CSS_SCHEDULING_GANTT_CELL_CLASS"
            class="gantt-header--load-rate text-ellipsis"
            :style="getDailyLoadRateStyle(day)"
          >
            <CapaTooltip
              :capa-percentage="getDailyLoadRate(day)"
              :computed-charge="dailyLoadRates[day]?.load"
              :charge-table="dailyLoadRates[day]?.loadDetail"
              :computed-capa="dailyLoadRates[day]?.capa"
              :unit="dailyLoadRates[day]?.unit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gantt-row-header--wrapper {
  position: sticky;
  top: var(--gantt-total-header-height);
  display: inline-flex;
  z-index: 3;
  background: rgb(var(--v-theme-newLayerBackground));

  .gantt-header {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    & .gantt-header--days-wrapper {
      display: flex;
      align-items: center;
      overflow: hidden;
      border-bottom: var(--gantt-border);

      & .gantt-header--day-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;

        &.is-last-week-day:not(:last-child)::after,
        &.is-last-displayed-day::after {
          content: "";
          position: absolute;
          top: 0;
          right: -1px;
          width: 1px;
          height: 100%;
          border-right: var(--gantt-border);
          z-index: 1;
        }
        &.is-last-displayed-day::after {
          right: 0;
        }

        & .gantt-header--day {
          border-bottom: var(--gantt-border);
          text-align: center;
          background: rgb(var(--v-theme-newLayerBackground));

          &.is-today {
            color: rgb(var(--v-theme-newLayerBackground));
          }
        }

        & .gantt-header--load-rate {
          display: flex;
          justify-content: center;
          font-size: 12px;
          line-height: 48px;
          border-right: var(--gantt-border);
        }

        // remove the very last shift's border-right to prevent overlapping with the one of .gantt-header--days-wrapper
        &.is-last-week-day,
        &:last-child {
          & .gantt-header--segments > div:last-child {
            border-right: none;
            width: calc(var(--gantt-mesh-month-cell-width) - 1px);
          }
        }
      }
    }
  }
}

.plan-wrapper {
  & .gantt-header--day.is-today {
    background: rgb(var(--v-theme-newPrimaryRegular)) !important;
  }
}

.piloting-wrapper {
  & .gantt-header--day.is-today {
    background: rgb(var(--v-theme-newOrangeRegular)) !important;
  }
  & .gantt-row--cell.is-today {
    background: rgb(var(--v-theme-newOrangeLight1)) !important;
  }
}

// overriding the height of the ::before element when displaying the shifts
// the 1px is the bottom border width applied to their wrapping element
.gantt-diagram--wrapper.mesh-week {
  /* FIXME: why ? */
  & .gantt-row__lazy-wrapper:nth-child(2) {
    margin-top: -6px;
  }

  & .gantt-header--wrapper {
    &::before,
    &::after {
      content: "";
      position: sticky;
      top: inherit;
      left: var(--gantt-prefix-width);
      width: 1px;
      height: calc(
        var(--gantt-header-height) + var(--gantt-shifts-line-height) + 1px
      );
      background: var(--gantt-border-color);
      border-top-left-radius: 8px;
      z-index: 2;
    }

    &::before {
      left: var(--gantt-prefix-width);
      border-top-left-radius: 8px;
    }

    &::after {
      right: 0;
    }
  }

  & .gantt-header--days-wrapper {
    // FIXME: temp for nicer display
    border-radius: 0;
  }
}

// overriding the border-radius when displaying the GanttHeaderMonth component, which displays the rounded borders
.gantt-header--wrapper
  .gantt-header
  .gantt-header--meshes-wrapper
  + .gantt-header--days-wrapper {
  border-radius: 0;
}
</style>
