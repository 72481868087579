<script lang="ts" setup>
import {computed, toRefs} from "vue";
import {storeToRefs} from "pinia";
import FChip from "@/components/Global/Homemade/Commons/FChip.vue";
import OperationStatusItem from "@/components/Scheduling/Operations/OperationStatusItem.vue";
import {usePermissionsStore} from "@/lib/stores/permissionsStore";
import {useOperationStatus} from "@/composables/scheduling/useOperationStatus";
import {schedulingStatusColorText} from "@/tscript/utils/schedulingUtils";
import {OF_STATUS, TEST_IDS} from "@/config/constants";
import {getMaxStatus} from "@oplit/shared-module";
import type {SchedulingOperation} from "@/interfaces";

interface OperationStatusProps {
  operation?: SchedulingOperation;
  disabled?: boolean;
  hideChipText?: boolean;
}

const props = withDefaults(defineProps<OperationStatusProps>(), {
  operation: () => null,
  disabled: false,
  hideChipText: false,
});

const emit = defineEmits<{
  (
    e: "change-status",
    update: {new_status: string; quantity_produced?: number},
  );
}>();

const {currentPermissions} = storeToRefs(usePermissionsStore());

const isDisabledMenu = computed(() => {
  if (!currentPermissions.value.scheduling.update_of_status) return true;
  return props.disabled;
});

const {operation} = toRefs(props);
const {
  displayedOperationStatus: computedStatus,
  displayedOperationStatusLabel: status,
} = useOperationStatus(operation);

function getChipColorFromStatus(status: string) {
  return status === OF_STATUS.DONE
    ? "newDisableText"
    : schedulingStatusColorText(status);
}

function getChipVariant(status: string) {
  return status === OF_STATUS.DONE ? "elevated" : "tonal";
}
function setStatus(new_status: string): void {
  const {quantite_of} = props.operation;

  if (new_status === status.value) return;
  if (new_status !== getMaxStatus([new_status, props.operation.erp_status]))
    return;

  const update: {new_status: string; quantity_produced?: number} = {
    new_status,
  };

  if (new_status === OF_STATUS.DONE)
    update.quantity_produced = quantite_of || 0;

  // further status-related logic is made in parents components
  emit("change-status", update);
}
</script>

<template>
  <v-menu
    v-if="!!status"
    :disabled="isDisabledMenu"
    location="top"
    content-class="border-none shadow-neutral-xl"
  >
    <template v-slot:activator="{props}">
      <slot
        name="activator"
        v-bind="{
          props,
          status: computedStatus.text,
          append: computedStatus.append,
        }"
      >
        <FChip
          v-bind="props"
          :color="getChipColorFromStatus(computedStatus.text)"
          :variant="
            hideChipText ? 'elevated' : getChipVariant(computedStatus.text)
          "
          :class="{'hide-chip-text': hideChipText}"
          class="operation-status__chip"
          small
        >
          <template v-if="!hideChipText">
            <span
              :data-testid="`${TEST_IDS.OPERATION_STATUS__ACTIVATOR_PREFIX}${status}`"
            >
              {{ $t(`operation.status.${computedStatus.text}`) }}
            </span>
            <span v-if="computedStatus.append" class="ml-1">
              {{ computedStatus.append }}
            </span>
          </template>
        </FChip>
      </slot>
    </template>
    <div class="d-flex flex-column">
      <OperationStatusItem
        v-for="s in Object.values(OF_STATUS)"
        :key="s"
        :status="s"
        :data-testid="`${TEST_IDS.OPERATION_STATUS__STATUS_PREFIX}${s}`"
        @click="() => setStatus(s)"
      />
    </div>
  </v-menu>
</template>

<style lang="scss" scoped>
.operation-status__chip {
  padding: 0 8px;

  &.hide-chip-text {
    padding: 0 9px;
  }
}

:deep(.v-chip__content) {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
