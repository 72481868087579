import {storeToRefs} from "pinia";

import {SchedulingOperation} from "@/interfaces";
import {prettifyNumber} from "@/tscript/utils/generalHelpers";

import {useMainStore} from "@/stores/mainStore";
import {useInternationalizedFunctions} from "@/composables/i18n/useInternationalizedFunctions";
import {useParametersStore} from "@/domains/parameters/stores/parametersStore";
import {useI18n} from "vue-i18n";

const useComputeQuantityUnit = () => {
  const mainStore = useMainStore();
  const {perimeters, machines} = storeToRefs(mainStore);
  const parameterStore = useParametersStore();
  const {parametersSchedulingDisplay, defaultSchedulingDisplayInfoQuantity} =
    storeToRefs(parameterStore);

  const {getOPUnit, getOFUnit} = useInternationalizedFunctions();

  const {t} = useI18n();

  /**
   * Gets the OP unit for a given OP and sector. Will use - as a fallback - the sector's
   * unit or the first assigned sector's unit in the case of a machine.
   * @param {SchedulingOperation} operation - The OP to fetch the unit for.
   * @return {string} The unit for the given OP - by order of priority: the OP's unit,
   * the sector's unit or the default unit.
   */
  function operationComputedOpQuantityUnit(
    operation: SchedulingOperation,
  ): string {
    if (!operation) return;

    const unit = getOPUnit(operation);

    if (unit) return unit;

    const operationCollection =
      operation.collection || operation.secteur_collection;
    let sector = (perimeters.value[operationCollection] || []).find(
      ({id}) => id === operation?.secteur_id,
    );

    if ([null, undefined, "sites"].includes(operationCollection) && !sector) {
      const machine = machines.value.find(
        ({id}) => id === operation.secteur_id,
      );
      const [assignation] = machine?.assignations || [];
      if (!machine || !assignation) return getOPUnit(operation, sector);

      sector = (perimeters.value[assignation.collection] || []).find(
        ({id}) => id === assignation.id,
      );
    }

    return getOPUnit(operation, sector);
  }

  function getUnitByOperationKey(
    key: string,
    operation: Partial<SchedulingOperation>,
  ) {
    switch (key) {
      case "quantite_of":
        return getOFUnit(operation);
      case "quantite_op":
        return operationComputedOpQuantityUnit(operation);
      case "op_duration":
        return t("Commons.hours");
      case "quantite_op_2":
        return operation.unite_op_2 || t("Commons.euros");
      default:
        return "";
    }
  }

  function operationComputedQuantity(
    operation: Partial<SchedulingOperation>,
    minifiedDisplay = false,
    includeOpQty = true,
  ): {
    firstQuantityUnit: string;
    secondQuantityUnit: string;
    firstQuantityValueAndUnit: string;
    secondQuantityValueAndUnit: string;
    secondQuantityText: string;
    fullQuantitiesText: string;
  } {
    const [firstQuantityField, secondQuantityField] =
      parametersSchedulingDisplay.value.info_quantity ||
      defaultSchedulingDisplayInfoQuantity.value;

    const firstQuantityValue = firstQuantityField
      ? operation[firstQuantityField]
      : null;
    const firstQuantityUnit = firstQuantityField
      ? getUnitByOperationKey(firstQuantityField, operation)
      : null;
    const firstQuantityValueAndUnit = `${prettifyNumber(
      firstQuantityValue || 0,
    )} ${firstQuantityUnit}`;

    const secondQuantityValue = secondQuantityField
      ? operation[secondQuantityField]
      : null;
    const secondQuantityUnit = secondQuantityField
      ? getUnitByOperationKey(secondQuantityField, operation)
      : null;
    const secondQuantityValueAndUnit = `${prettifyNumber(
      secondQuantityValue || 0,
    )} ${secondQuantityUnit}`;
    const secondQuantityText = `(${secondQuantityValueAndUnit})`;

    const fullQuantitiesText = minifiedDisplay
      ? [
          firstQuantityValueAndUnit,
          `(${prettifyNumber(
            secondQuantityValue || 0,
          )} ${secondQuantityUnit?.charAt(0)})`,
        ]
      : [firstQuantityValueAndUnit, secondQuantityText];

    if (!includeOpQty) fullQuantitiesText.splice(1, 1);

    return {
      firstQuantityUnit,
      secondQuantityUnit,
      firstQuantityValueAndUnit,
      secondQuantityValueAndUnit,
      secondQuantityText,
      fullQuantitiesText:
        firstQuantityValue == null
          ? secondQuantityValueAndUnit
          : firstQuantityValueAndUnit === secondQuantityValueAndUnit
          ? firstQuantityValueAndUnit
          : fullQuantitiesText.join(" "),
    };
  }

  /**
   * returns fields & values used for display inside OperationsActionsMenu
   */
  function getSelectedOperationDetail(operation: SchedulingOperation) {
    return {
      quantite_of: operation.quantite_of,
      quantite_op: operation.quantite_op ?? operation.quantite,
      quantite_op_2: operation.quantite_op_2,
      op_duration: operation.op_duration,
      unite_of: getOFUnit(operation),
      unite_op: operationComputedOpQuantityUnit(operation),
    };
  }

  return {
    operationComputedOpQuantityUnit,
    operationComputedQuantity,
    getSelectedOperationDetail,
  };
};

export default useComputeQuantityUnit;
