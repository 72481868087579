// rm app-engine/src/config/version.ts && ln shared/config/version.ts app-engine/src/config/version.ts && rm webapp/src/config/version.ts && ln shared/config/version.ts webapp/src/config/version.ts
export const VERSION = "2.8.1";
// ---
// v2.8.1
// SSO pour Saint-Gobain Sully
// Cycle 26
// Vue en-cours - Je veux choisir les quantités affichées en ordo
// Stocks - Ajuster au stock cible pondéré
// Lissage UI sur le nom de la colonne à utiliser dans les règles d'import
// Pouvoir mettre mes groupes de secteurs en favori pour chaque user
