<script setup lang="ts">
import {computed, watch} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import OperationsEditDialog from "@/components/Scheduling/Operations/OperationsEditDialog.vue";
import OperationsBatchDialog from "@/components/Scheduling/Piloting/OperationsBatchDialog.vue";
import {usePermissionsStore} from "@/lib/stores/permissionsStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useSelectedOperations} from "@/stores/selectedOperationsStore";
import useComputeQuantityUnit from "@/composables/useComputeQuantityUnit";
import {prettifyNumber} from "@/tscript/utils/generalHelpers";
import {CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS} from "@/config/constants";
import {
  HTMLElementStyleObject,
  OperationsActionsMenuSelectedOps,
} from "@/interfaces";

type Arrow = {
  type: "arrow" | "chevrons";
  direction: "Up" | "Down" | "Left" | "Right";
};

const defaultPositionValue = null;

export interface OperationsActionsMenuProps {
  showActionsMenu?: boolean;
  displaySide?: "right" | "left";
  /**
   * returns a configuration object from another component to disable/enable specific keys
   * and show justificating tooltips when disabled\
   * these are meant to be items which keys are the ones in arrowDirections and the associated values:
   * - for state: a boolean that answers "is this arrow displayed ?"
   * - for tooltips: the reason of the disablance
   */
  arrowsState?: {
    state: Record<keyof Arrow["direction"], boolean>;
    tooltips: Record<keyof Arrow["direction"], string | null>;
  };
  isOperationOngoing?: boolean;
  hasDoneOps?: boolean;
  hideVerticalArrows?: boolean;
  hideHorizontalArrows?: boolean;
  hideEditDialog?: boolean;
  showBatchModal?: boolean;
  shiftPagination?: {current: number; max: number};
  teleport?: string;
  backgroundColor?: string;
  selectedOps?: OperationsActionsMenuSelectedOps;
  /**
   * top & bottom values have effect on the absolute position directly
   * right & left act as offsets (to not interfere with the `displaySide` property)
   */
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
}

const props = withDefaults(defineProps<OperationsActionsMenuProps>(), {
  displaySide: "right",
  arrowsState: () => null,
  shiftPagination: () => ({current: -1, max: -1}),
  selectedOps: () => ({} as OperationsActionsMenuSelectedOps),
  top: defaultPositionValue,
  right: defaultPositionValue,
  bottom: defaultPositionValue,
  left: defaultPositionValue,
});

const emit = defineEmits<{
  (
    e: "trigger-key-event",
    code: "ArrowUp" | "ArrowRight" | "ArrowDown" | "ArrowLeft",
  );
  (e: "change-operation-shift", code: "up" | "down");
  (e: "is-displayed", isDisplayed: boolean);
}>();

const {currentPermissions} = storeToRefs(usePermissionsStore());
const {canOperateOnOperationCards} = storeToRefs(useSchedulingStore());
const {selectedOperations, formattedSelectedOperationsForRetrocompatibility} =
  storeToRefs(useSelectedOperations());
const {operationComputedQuantity} = useComputeQuantityUnit();
const {getOperationsAggregatedQuantities} = useSelectedOperations();

const hasShiftArrowUp = computed(() => props.shiftPagination.current > 0);
const hasShiftArrowDown = computed(
  () => props.shiftPagination.current < props.shiftPagination.max,
);
const isDisplayedActionsMenu = computed(() => {
  if (!props.showActionsMenu) return false;
  return canOperateOnOperationCards.value;
});
const areEnabledHorizontalArrows = computed(() => {
  if (!currentPermissions.value.scheduling.update_of_date) return false;
  return !props.hideHorizontalArrows;
});
const arrowDirections = computed<Arrow["direction"][]>(() => {
  const directions = [];
  if (areEnabledHorizontalArrows.value) directions.push("Right", "Left");
  if (!props.hideVerticalArrows) directions.push("Up", "Down");
  return directions;
});
const isDisplayedArrowsContainer = computed(() => {
  if (arrowDirections.value.length === 0) return false;
  return !props.hideHorizontalArrows || !props.hideVerticalArrows;
});
const actualSelectedOps = computed(() => {
  if (selectedOperations.value.length > 0)
    return formattedSelectedOperationsForRetrocompatibility.value;

  return props.selectedOps;
});
const selectedOpsIDs = computed(() => Object.keys(actualSelectedOps.value));
const selectedOpsAggregatedValues = computed(() => {
  const operations = Object.values(actualSelectedOps.value || {});
  const {firstQuantityValueAndUnit, secondQuantityText} =
    operationComputedQuantity({
      ...operations[0], // retrieving units from first operation
      ...getOperationsAggregatedQuantities(operations),
    });

  return {
    firstQuantityValueAndUnit,
    secondQuantityText,
  };
});
const isDisplayedBatchDialog = computed(() => {
  if (!props.showBatchModal) return false;

  const operations = Object.values(actualSelectedOps.value);

  if (operations.length === 0) return false;

  if (operations.every(({batch_id}) => !batch_id)) return true;

  const batchIds = _.uniq(operations.map(({batch_id}) => batch_id)).filter(
    Boolean,
  );

  if (batchIds.length === 1) return true;

  return false;
});
const displayedArrows = computed((): Arrow[] => {
  const displayedArrows = arrowDirections.value.map(
    (direction: Arrow["direction"]) => ({
      type: "arrow",
      direction,
    }),
  );

  if (hasShiftArrowUp.value)
    displayedArrows.unshift({type: "chevrons", direction: "Up"});
  if (hasShiftArrowDown.value)
    displayedArrows.push({type: "chevrons", direction: "Down"});

  return displayedArrows as Arrow[];
});
const getActionsMenuStyle = computed((): HTMLElementStyleObject => {
  const styleObject: HTMLElementStyleObject = {};
  for (const position of ["top", "right", "bottom", "left"]) {
    if (props[position] !== defaultPositionValue) {
      const property = ["left", "right"].includes(position)
        ? `margin-${position}`
        : position;

      styleObject[property] = Number.isNaN(+props[position])
        ? props[position]
        : `${props[position]}px`;
    }
  }
  return styleObject;
});
const tooltipConfigByDirection = computed<{text: string; disabled: boolean}>(
  () => {
    const {tooltips = {}} = props.arrowsState || {};

    return arrowDirections.value.reduce((acc, direction) => {
      const text = tooltips[direction] || "";

      acc[direction] = {
        text,
        disabled: !text,
      };

      return acc;
    }, {});
  },
);

function isDisabledArrow(direction: Arrow["direction"]): boolean {
  if (arrowDirections.value.indexOf(direction) === -1) return true;
  if (!props.arrowsState?.state) return false;
  const {state} = props.arrowsState;
  /**
   * operations that are done cannot have their `day_date`s updated
   */
  if (["Left", "Right"].includes(direction) && props.hasDoneOps) return true;
  return direction in state && !state[direction];
}
function onArrowClick({type, direction}: Arrow): void {
  if (type === "arrow" && !isDisabledArrow(direction))
    emit("trigger-key-event", `Arrow${direction}`);
  else if (type === "chevrons")
    emit("change-operation-shift", direction.toLowerCase() as "up" | "down");
}

watch(isDisplayedActionsMenu, (v) => emit("is-displayed", v));
</script>

<template>
  <transition name="bounce-in">
    <Teleport
      v-if="isDisplayedActionsMenu"
      :disabled="!teleport"
      :to="teleport"
      defer
    >
      <div
        v-if="isDisplayedActionsMenu"
        :class="[
          CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
          {'hide-vertical-arrows': hideVerticalArrows},
          {'hide-edit-dialog': hideEditDialog},
          {'has-shift-arrow--up': hasShiftArrowUp},
          {'has-shift-arrow--down': hasShiftArrowDown},
          {[`bg-${backgroundColor}`]: !!backgroundColor},
          `display-${displaySide}`,
        ]"
        v-bind="$attrs"
        class="operation-card-actions-menu print-hide"
        :style="getActionsMenuStyle"
        data-testid="operation-card-actions-menu"
      >
        <div v-if="isDisplayedArrowsContainer" class="navigation-dot-container">
          <div
            :class="[
              {'is-operation-ongoing': isOperationOngoing},
              'navigation-dot',
            ]"
          >
            <v-progress-circular
              v-if="isOperationOngoing"
              :color="backgroundColor ? 'blanc' : 'newMainText'"
              size="18"
              width="2"
              class="ma-auto"
              indeterminate
            />
            <template v-else>
              <span
                v-for="{type, direction} in displayedArrows"
                v-tooltip="tooltipConfigByDirection[direction]"
                :key="`${type}-${direction}`"
                :class="[
                  `${type}--${direction}`,
                  {disabled: type === 'arrow' && isDisabledArrow(direction)},
                ]"
                :data-testid="`operation-card-arrow-${type}-${direction}`"
              >
                <vue-feather
                  :type="`${type}-${direction.toLowerCase()}`"
                  size="16"
                  @click.stop="onArrowClick({type, direction})"
                />
              </span>
            </template>
          </div>
        </div>

        <div class="operations-actions-menu__selected-operations-details">
          <span>
            {{ selectedOpsIDs.length }}
            {{ $t("scheduling.operations", selectedOpsIDs.length) }}
          </span>

          <span>
            {{ selectedOpsAggregatedValues.firstQuantityValueAndUnit }}
          </span>

          <span>
            {{ selectedOpsAggregatedValues.secondQuantityText }}
          </span>
        </div>

        <OperationsEditDialog
          v-if="!hideEditDialog"
          :selected-ops-ids="selectedOpsIDs"
        />

        <OperationsBatchDialog v-if="isDisplayedBatchDialog" />
      </div>
    </Teleport>
  </transition>
</template>

<style lang="scss">
$dotContainerPadding: 24px;
$arrowsPadding: 4px;
$actionsMenuWidth: 180px;
.operation-card-actions-menu {
  position: absolute;
  width: $actionsMenuWidth;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: rgb(var(--v-theme-newLayerBackground));
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 12px;
  z-index: 6;
  &.display-right {
    right: #{($actionsMenuWidth + 5) * -1};
  }
  &.display-left {
    left: #{($actionsMenuWidth + 5) * -1};
  }
  & .v-btn {
    background-color: rgb(var(--v-theme-newAppBackground)) !important;
    border: 1px solid rgb(var(--v-theme-newSelected));
    color: rgb(var(--v-theme-newMainText));

    & .button-text > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }

  & .navigation-dot-container {
    padding: $dotContainerPadding;

    & .navigation-dot {
      position: relative;
      margin: auto;
      width: 4px;
      height: 4px;
      background: rgb(var(--v-theme-newDisableText));
      border-radius: 50%;

      &.is-operation-ongoing {
        background: transparent;

        & .v-progress-circular {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      & [class^="arrow--"],
      [class^="chevrons--"] {
        position: absolute;
        cursor: pointer;
        padding: $arrowsPadding; /* allows better click experience */
        display: flex;
        align-items: center;

        & svg {
          stroke: rgb(var(--v-theme-newSubText));
        }

        &.disabled {
          cursor: not-allowed;

          & svg {
            stroke: rgb(var(--v-theme-newSelected)) !important;
          }
        }
      }

      /*
      * somehow the up arrow is not properly placed with the mathematics value
      */
      & .arrow--Up {
        top: #{$dotContainerPadding * -1.1};
        left: 50%;
        transform: translateX(-50%);
      }
      & .arrow--Right {
        right: #{$dotContainerPadding * -1.15 - $arrowsPadding};
        top: 50%;
        transform: translateY(-50%);
      }
      & .arrow--Down {
        bottom: #{$dotContainerPadding * -1.1};
        left: 50%;
        transform: translateX(-50%);
      }
      & .arrow--Left {
        left: #{$dotContainerPadding * -1.15 - $arrowsPadding};
        top: 50%;
        transform: translateY(-50%);
      }
      & .chevrons--Up {
        top: #{$dotContainerPadding * -2};
        left: 50%;
        transform: translateX(-50%);
      }
      & .chevrons--Down {
        bottom: #{$dotContainerPadding * -2};
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  /** present when `backgroundColor` prop is passed */
  &[class*="bg-"] {
    & .navigation-dot {
      background: rgb(var(--v-theme-blanc));

      & svg {
        stroke: rgb(var(--v-theme-blanc)) !important;
      }
    }
  }

  &.hide-vertical-arrows {
    & .navigation-dot-container {
      padding: calc($dotContainerPadding / 2);
    }
  }
  &.has-shift-arrow--up .navigation-dot-container {
    padding-top: $dotContainerPadding * 2;
  }
  &.has-shift-arrow--down .navigation-dot-container {
    padding-bottom: $dotContainerPadding * 1.5;
  }

  & .operations-actions-menu__selected-operations-details {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: normal;
    line-height: 1.25;
  }
}
</style>
