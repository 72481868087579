import _ from "lodash";
import {
  STOCKS_TABLE_VIEWS,
  STOCKS_VALUATION_WEIGHTED_FIELDS,
} from "@/domains/stocks/constants";
import {GenericConfigObject} from "@/interfaces";
import {
  Demand,
  Gamme,
  GroupedByObject,
  Product,
  RecursiveGroupBy,
  StocksBaseEntity,
} from "@/domains/stocks/types";
import {toSafeString} from "@oplit/shared-module";

// returns the primary key for the next layer of entities (defined in STOCKS_TABLE_VIEWS)
const getChildrenPrimaryKey = (
  field: string,
  forceFieldDefinition = false,
): string =>
  forceFieldDefinition && !field
    ? null
    : STOCKS_TABLE_VIEWS[STOCKS_TABLE_VIEWS.indexOf(field) + 1];

// we use this function to use the unicity of the id key while displaying a human-readable version
const getLabelKey = (key: string) => toSafeString(key).replace("_id", "_name");

const getFirstColumnTypeFromField = (field: string): string => {
  switch (field) {
    case "day_date":
    case "month_date":
      return "date";
    default:
      return "text";
  }
};

/**
 * this represents the content for the `products` key for the `changeProductionObjective` function
 * the `children_articles` key is only present for the `famille` grouping and contains the keys used by the back to perform the update
 */
const getProductionObjectiveProductsArray = (product: Product) =>
  product.children_articles?.map((child) => ({
    ...child,
    is_last_operation: true,
  })) ?? [product];

const getStockItemUniqueKey = (item: Gamme): string => {
  return [
    item.article_id,
    item.client_id,
    item.parent_id,
    item.secteur_id,
    item.num_sequence,
  ].join("-");
};

const hasSameStockItemUniqueKey = (
  item: StocksBaseEntity,
  other: StocksBaseEntity,
): boolean => {
  return getStockItemUniqueKey(item) === getStockItemUniqueKey(other);
};

/**
 * creates an object that follows the rules of the following example recursively (based on the length of the groupBy's)
 * e.g. with a groupBy that groups on `customer` and on `famille`
 * {
 *   <customer_name_1>: {
 *    <famille_name_alpha>: [associated_demands]
 *    <famille_name_beta>: [associated_demands]
 *    ...
 *   },
 *   <customer_name_2>{
 *    <famille_name_beta>: [associated_demands]
 *    <famille_name_zeta>: [associated_demands]
 *    ...
 *   },
 * }
 */
const recursiveGroupBy = (
  demandsArray: Demand[],
  groupByArray: string[],
): RecursiveGroupBy => {
  if (groupByArray.length === 1)
    return _.groupBy(demandsArray, groupByArray[0]);
  else {
    const currentGroupByProp = groupByArray.shift();
    const grouped = _.groupBy(demandsArray, currentGroupByProp);
    return _.mapValues(grouped, (group) => {
      return recursiveGroupBy(group, groupByArray.slice());
    });
  }
};

/**
 * filter the groupBy object recursively, based on the breadcrumbs content
 */
const recursiveBreadcrumb = (
  groupByObject: RecursiveGroupBy,
  breadcrumbs: GenericConfigObject[],
) => {
  /**
   * early return to prevent logic break\
   * this is required when the breadcrumb is set from a stored value
   * that isn't available in current options anymore
   */
  if (!groupByObject) return [];
  if (!breadcrumbs.length)
    return Object.values(groupByObject).map(getMappedGroupedByObject);
  const {value} = breadcrumbs.shift();
  return recursiveBreadcrumb(
    groupByObject[value as string] as RecursiveGroupBy,
    breadcrumbs,
  );
};

const getMappedGroupedByObject = (
  object: Demand[] | RecursiveGroupBy,
): GroupedByObject => {
  const isOperation = Array.isArray(object) ? false : !!object.article_id;
  const array = isOperation ? [object] : Object.values(object).flat();
  const children_count = isOperation ? 0 : Object.keys(object).length;
  return {array, children_count, isOperation};
};

/**
 * shorthand function to retrieve the proper object key for stocks KPIs calculations
 */
const getWeightedKey = (key: string, shouldWeight = false): string =>
  shouldWeight && STOCKS_VALUATION_WEIGHTED_FIELDS.includes(key)
    ? `weighted_${key}`
    : key;

export {
  getChildrenPrimaryKey,
  getLabelKey,
  getFirstColumnTypeFromField,
  getProductionObjectiveProductsArray,
  getStockItemUniqueKey,
  hasSameStockItemUniqueKey,
  getWeightedKey,
  recursiveGroupBy,
  recursiveBreadcrumb,
};
