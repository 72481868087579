import {ref, computed, onMounted, inject} from "vue";
import _ from "lodash";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";
import {useMainStore} from "@/stores/mainStore";
import {
  getReadableImportParsingRuleValue,
  toSafeString,
} from "@oplit/shared-module";
import type {OpenSnackbarFunction, NomenclatureProduct} from "@/interfaces";

export function useNomenclatures() {
  const {apiClient} = storeToRefs(useMainStore());
  const {t} = useI18n();

  const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");

  const nomenclatureList = ref<NomenclatureProduct[]>([]);

  const displayedNomenclatureList = computed<NomenclatureProduct[]>(() =>
    (nomenclatureList.value ?? []).map(
      ({product_name, child_product_name, ...rest}) => ({
        ...rest,
        product_name: getReadableImportParsingRuleValue(product_name),
        child_product_name:
          getReadableImportParsingRuleValue(child_product_name),
      }),
    ),
  );

  onMounted(async () => loadNomenclatures());

  type GetOnlySelectedKeysResponse = {
    product_name: string;
    child_product_name: string;
    coefficient: number;
  };

  type TempCsvFile = {
    Parent: string;
    Enfant: string;
    Coefficient: string;
  };

  type KeyToSelect = {title: string; slug: string; key: string};
  const getOnlySelectedKeys = (
    keyToSelect: KeyToSelect[],
    csvColumn: TempCsvFile,
  ): GetOnlySelectedKeysResponse => {
    const response = {};
    for (const item of keyToSelect) {
      if (
        item.slug === "coefficient" &&
        !isNaN(+toSafeString(csvColumn[item.slug]).replace(/,/g, "."))
      ) {
        // convert to a number
        response[item.key] = +toSafeString(csvColumn[item.slug]).replace(
          /,/g,
          ".",
        );
      } else if (csvColumn[item.slug] && item.slug !== "coefficient")
        response[item.key] = toSafeString(csvColumn[item.slug]);
      else {
        throw new Error(
          t("Parametres.nomenclature.import_file_error", {key: item.slug}),
        );
      }
    }
    return response as GetOnlySelectedKeysResponse;
  };

  // add a delay to open it because the snackBar closes with the modal closing
  const openLoadingSnackBar = (config) => {
    setTimeout(() => openSnackbar(config), 50);
  };

  const loadNomenclatures = async () => {
    const results = await apiClient.value.getNomenclatures();
    nomenclatureList.value = results || [];
  };

  /**
   * Retourne la liste de nomenclatures triée directement dans le front au moment de l'import du fichier
   */
  const getSortedNomenclatures = (newImportProducts: NomenclatureProduct[]) => {
    const results = _.sortBy(
      [...newImportProducts],
      [
        (x) => toSafeString(x.product_name.toLowerCase()),
        (x) => toSafeString(x.child_product_name).toLowerCase(),
      ],
    );
    return results;
  };

  const updateNomenclatures = async (data: NomenclatureProduct[]) => {
    openLoadingSnackBar({loading: true, message: t("Import.imported_notice")});
    try {
      await apiClient.value.updateNomenclatures(data);
      nomenclatureList.value = getSortedNomenclatures(data);
      openLoadingSnackBar({loading: false});
      openLoadingSnackBar({message: t("Import.imported_file_success")});
    } catch (error) {
      openLoadingSnackBar({loading: false});
      openSnackbar(null, null, error);
    }
  };

  return {
    displayedNomenclatureList,
    nomenclatureList,
    getOnlySelectedKeys,
    loadNomenclatures,
    updateNomenclatures,
    getSortedNomenclatures,
  };
}
