<template>
  <div
    class="dropdown-list dropdown-list--perimeters d-flex flex-column"
    data-cy="dropdown-list-perimeters"
  >
    <div class="dropdown-header" v-if="title || !hideSearch">
      <h4 v-if="title">{{ title }}</h4>

      <FTextField
        v-if="!hideSearch"
        v-model="search"
        clearable
        flush-label
        class="dropdown-search"
        icon="search"
        :label="label || $t('global.search')"
        data-cy="fdropdown-perimeter-search"
        @update:model-value="onSearchChange"
        @click:clear="() => (search = '')"
        @click.stop
      />
    </div>

    <slot name="prepend-item" />

    <v-list
      v-if="!hideContent"
      :class="{
        'dropdown-content-perimeters': true,
        'keep-scrollbar padded-scrollbar dense-scrollbar': keepScrollbar,
        'spread pa-2 mt-2': spread,
      }"
      data-cy="dropdown-list-perimeters"
    >
      <template v-if="hasSectorsGroups">
        <div
          class="fbody-1 text-newMainText font-weight-bold py-3 px-4 fd-flex-center gap-4"
        >
          <OplitIcon :stroke="variables.newSubText" name="sector" />

          {{ $t("dropdown_list_perimeters.sectors_groups") }}
        </div>

        <v-list-item
          v-for="sectorGroup in displayedSectorsGroups"
          :key="sectorGroup.id"
          :class="{
            'bg-newPrimaryRegular text-blanc': isSelectedGroup(sectorGroup),
          }"
          class="f-dropdown-list-perimeters__sector-group-list-item pl-6"
          link
          @click="selectSectorGroup(sectorGroup)"
        >
          <SectorGroupItem
            :sector-group="sectorGroup"
            :dark="isSelectedGroup(sectorGroup)"
          />
        </v-list-item>

        <div class="fbody-1 text-newMainText font-weight-bold py-3 px-4">
          {{ $t("dropdown_list_perimeters.factory_structure") }}
        </div>
      </template>

      <v-list-item
        v-if="showToggleAll"
        link
        class="nav-liste-item"
        data-testid="dropdown-toggle-all-btn"
        @click="onToggleAll"
      >
        {{
          cleanSelectedSectors.length > 0
            ? $t("global.deselect_all")
            : $t("global.select_all")
        }}
      </v-list-item>

      <NavListeMiddleWare
        v-for="factory in firstLevelSectors"
        :key="getNavListKey(factory)"
        hide-nb-children
        :secteur="factory"
        :level="computeLevel(factory)"
        :niveau="0"
        :perimetres="computedPerimetres"
        :on-item-selected="onItemSelected"
        :toggle="toggle"
        :single-select="singleSelect"
        :multi-select="multiSelect"
        :on-select-all="onSelectAll"
        :can-select-all="canSelectAll"
        :mercateam-sectors="mercateamSectors"
        :has-child-selected="sectorHasChildSelected(factory)"
        :only-select-last-level="onlySelectLastLevel"
      />
    </v-list>

    <v-card-actions v-if="multiSelect && !hideBtn">
      <FButton
        v-if="!!onResetAll"
        textClass="text-newPrimaryRegular"
        @click="onResetAll"
      >
        {{ $t("global.clear") }}
      </FButton>
      <v-spacer></v-spacer>
      <FButton
        filled
        data-testid="validate-fdropdown-list-perimeters"
        @click="onConfirmDropdown"
      >
        {{ $t("global.valider") }}
      </FButton>
    </v-card-actions>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, PropType, computed} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import {
  levelCorresp,
  reverseLevelCorresp,
  merca_levels,
  searchSubstring,
  getSortedPerimeters,
} from "@oplit/shared-module";
import NavListeMiddleWare from "@/components/Navigation/NavListeMiddleware.vue";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import FTextField from "@/components/Global/Homemade/Inputs/FTextField.vue";
import {Sector, SectorGroup, IOverloadedPerimetres} from "@/interfaces";
import SectorGroupItem from "@/components/Commons/SectorGroupItem.vue";
import {useParametersStore} from "@/domains/parameters/stores/parametersStore";
import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "figma-dropdown-list-perimeters",
  components: {NavListeMiddleWare, FButton, FTextField, SectorGroupItem},
  props: {
    value: String,
    onChange: {type: Function as PropType<() => void>, default: () => void 0},
    onChangeMultiple: {
      type: Function as PropType<(...args) => void>,
      default: null,
    },
    placeholder: String,
    title: String,
    disabled: Boolean,
    forcedLevel: Object,
    startOnForcedLevel: {type: Boolean, default: false},
    hideSearch: Boolean,
    defaultPath: Object,
    keepScrollbar: Boolean,
    keepOpen: {type: Boolean, default: false},
    toggleDefault: {type: Boolean, default: false},
    singleSelect: {type: Boolean, default: false},
    multiSelect: {type: Boolean, default: false},
    canSelectAll: {type: Boolean, default: false},
    spread: {type: Boolean, default: false},
    selectedSectors: {type: Array, default: () => []},
    onSelectAll: {
      type: Function as PropType<
        (
          sector: Sector,
          level: {level: number},
          hasChildSelected?: boolean,
        ) => void
      >,
      default: () => void 0,
    },
    // `null` by default for the display condition
    onResetAll: {type: Function as PropType<() => void>, default: () => void 0},
    mercateamSectors: {type: Boolean, default: false},
    onlySelectLastLevel: {type: Boolean, default: false},
    label: {type: String, default: ""},
    hideBtn: {type: Boolean, default: false},
    displayMachineTags: {type: Boolean, default: false},
    // function called upon clicking on the "confirm" butto
    onConfirm: {type: Function as PropType<() => void>, default: () => void 0},
    hideSectorsGroups: {type: Boolean, default: false},
    hideContent: {type: Boolean, default: false},
  },
  emits: ["close"],
  setup(props) {
    const {parametersSectorGroups} = storeToRefs(useParametersStore());
    const mainStore = useMainStore();
    const {
      perimeters,
      userData,
      machines,
      stations,
      mercateamSectors: storeMercateamSectors,
      cleanMachineTags,
      activePerim,
      variables,
    } = storeToRefs(mainStore);
    const {getSortedSectorsGroups} = mainStore;

    const search = ref("");
    const overloadedPerimetres = ref<IOverloadedPerimetres>({});
    const merca_perimeters = ref({});

    const cleanSelectedSectors = computed(() =>
      (props.selectedSectors || []).filter((s) => !!s),
    );

    function isSectorSelected(
      sector: Sector,
      shouldBeMachine = false,
    ): boolean {
      return cleanSelectedSectors.value.some(
        (sect: Sector) =>
          (!shouldBeMachine || sect.is_machine) && sect.id === sector?.id,
      );
    }

    function getNavListKey(sector: Sector): string {
      return `${sector?.id}-${isSectorSelected(sector)}-${
        sector.open ? "open" : "close"
      }`;
    }

    const computedForcedLevel = computed(() => {
      if (props.forcedLevel?.id) return props.forcedLevel;
      const {sites} = overloadedPerimetres.value || {};
      if (!sites?.length || sites.length === 1) return _.get(sites, 0) || {};
      const {site_id} = activePerim.value || {};
      return sites.find((x: any) => x.id === site_id) || _.get(sites, 0) || {};
    });

    const filteredSectorsGroups = computed<SectorGroup[]>(() => {
      const sectorGroups = parametersSectorGroups.value as SectorGroup[];

      return sectorGroups.filter((sectorGroup) => {
        if (!searchSubstring(sectorGroup.name, search.value)) return false;

        // All sectors in the sector group must be in computedForcedLevel structure
        const {level, type, id} = computedForcedLevel.value as Sector;
        if (level === 0) return true;

        return sectorGroup.sectors.every(({secteur_id}) => {
          const poste = stations.value.find(({id}) => id === secteur_id);
          if (!poste) return false;
          return poste.level >= level && poste[type + "_id"] === id;
        });
      });
    });
    const displayedSectorsGroups = computed<SectorGroup[]>(() =>
      getSortedSectorsGroups(filteredSectorsGroups.value),
    );

    return {
      cleanSelectedSectors,
      parametersSectorGroups,
      perimeters,
      userData,
      machines,
      stations,
      storeMercateamSectors,
      cleanMachineTags,
      activePerim,
      search,
      overloadedPerimetres,
      merca_perimeters,
      isSectorSelected,
      getNavListKey,
      filteredSectorsGroups,
      computedForcedLevel,
      variables,
      displayedSectorsGroups,
    };
  },
  computed: {
    highestLevel() {
      if (this.mercateamSectors) {
        return (
          merca_levels.find((level: any, i: number) => {
            if (i === 0) return false;
            return this.merca_perimeters[level.collection]?.length;
          }) || {}
        );
      }
      return (
        this.forcedLevel ||
        levelCorresp.find((level: any, i: number) => {
          if (i === 0) return false;
          return this.overloadedPerimetres[level.collection]?.length;
        }) ||
        {}
      );
    },

    firstLevelSectors() {
      const {
        mercateamSectors,
        merca_perimeters,
        overloadedPerimetres,
        highestLevel,
        computedForcedLevel: forcedLevel,
        startOnForcedLevel,
      } = this;
      let arr = [];
      if (mercateamSectors)
        arr = merca_perimeters[highestLevel.collection] || [];
      else if (forcedLevel?.id) {
        const parentMatch = (
          overloadedPerimetres[forcedLevel.collection] || []
        ).find((x: any) => x.id === forcedLevel.id);

        arr = startOnForcedLevel
          ? [parentMatch] // If start on forced level is true, we don't get only the children but itself
          : (parentMatch?.children || []).map((child: any) => {
              if (!child) return null;
              const match = overloadedPerimetres[child.collection].find(
                (x: any) => x.id === child.id,
              );
              return match;
            }) || [];
      } else arr = overloadedPerimetres[highestLevel.collection] || [];
      // we filter out `undefined` values from parentMatch/match
      return getSortedPerimeters(arr.filter(Boolean));
    },
    computedPerimetres() {
      return this.mercateamSectors
        ? this.merca_perimeters
        : this.overloadedPerimetres;
    },
    showToggleAll(): boolean {
      return (
        this.multiSelect &&
        this.firstLevelSectors.every((sector: Sector) => !sector.nb_children)
      );
    },
    hasSectorsGroups(): boolean {
      return (
        !this.hideSectorsGroups &&
        this.multiSelect &&
        this.filteredSectorsGroups?.length > 0
      );
    },
  },
  watch: {
    perimeters: {
      deep: true,
      immediate: true,
      handler: function (val: any) {
        this.overloadPerimetres(val);
      },
    },
    cleanSelectedSectors: {
      deep: true,
      handler: function () {
        this.overloadPerimetres(this.perimeters);
      },
    },
    storeMercateamSectors: {
      immediate: true,
      handler: function (val: any) {
        if (val?.sectors_arr?.length) this.loadMercaPerimeters();
      },
    },
  },
  methods: {
    onToggleAll(): void {
      const remove = this.cleanSelectedSectors.length > 0;
      for (const sector of this.firstLevelSectors)
        this.onItemSelected(sector, sector.level, null, remove);
    },
    overloadPerimetres(perimetres: any) {
      if (!perimetres || !perimetres.sites?.length) return;
      const {displayMachineTags, cleanSelectedSectors, cleanMachineTags} = this;
      let overloadedPerimetres: any = {};

      reverseLevelCorresp.forEach((level: any, idx) => {
        const secteurs = perimetres[level.collection] || [];
        const filteredSectors = this.filterOnSearch(
          secteurs,
          this.search,
          idx,
          level,
          overloadedPerimetres,
        );
        overloadedPerimetres[level.collection] = filteredSectors.map(
          (x: any, i: number) => {
            const {machine_tags} = x;
            let {children} = x;
            if (displayMachineTags && machine_tags?.length) {
              //This is to handle the case where we want to display machine_tags under their affected sectors
              children = [
                ...(children || []),
                ...machine_tags
                  .map((m: any) => {
                    const match = cleanMachineTags.find(
                      (x: any) => x.id === m.id,
                    );
                    if (!match) return;
                    const {id, name, updated_at} = match;
                    return {
                      id,
                      name,
                      updated_at,
                      is_machine: true,
                      collection: "sites",
                      type: "site",
                      level: 0,
                      selected: this.isSectorSelected(m, true),
                    };
                  })
                  .filter(Boolean),
              ];
            }

            return {
              ...x,
              ...(children?.length && {children}),
              open:
                _.get(
                  this.overloadedPerimetres,
                  [level.collection, i, "open"],
                  false,
                ) ||
                _.get(this.defaultPath, [level.type + "_id"]) === x.id ||
                (this.toggleDefault && _.get(this.defaultPath, "id") === x.id),
              active:
                this.toggleDefault && _.get(this.defaultPath, "id") === x.id,
              selected: cleanSelectedSectors.some(
                (y: any) => y.id === x.id && y.collection === x.collection,
              ),
            };
          },
        );
      });
      this.overloadedPerimetres = overloadedPerimetres;
    },

    async loadMercaPerimeters() {
      const {storeMercateamSectors} = this;
      const {sectors_arr, flat_structure} = storeMercateamSectors || {};
      if (!sectors_arr?.length) return;
      merca_levels.forEach((level: any, idx: number) => {
        const lowerLevel = merca_levels[idx + 1];
        this.merca_perimeters[level.collection] = (
          flat_structure[level.collection] || []
        ).map((x: any) => {
          let children: any = [];
          if (idx < merca_levels.length - 1) {
            children = (flat_structure[lowerLevel.collection] || []).filter(
              (y: any) => {
                const parentMatch = y[level.type + "_id"] === x.id;
                if (!parentMatch) return;
                if (lowerLevel.collection === "subpositions")
                  return y.team_id === x.team_id;
                else return true;
              },
            );
          }

          return {
            ...x,
            ...(children.length && {children: _.sortBy(children, ["name"])}),
            open: false,
            active: false,
            selected: this.isSectorSelected(x),
          };
        });
      });
    },
    computeLevel(factory: any) {
      if (this.mercateamSectors)
        return merca_levels.find((x) => x.type === factory.type);
      return levelCorresp.find((x: any) => x.type === factory.type);
    },
    toggle: async function (secteur: any, level: any) {
      const secteurs = _.get(this.computedPerimetres, level.collection, []);
      const newSecteurs = secteurs.map((x: any) => ({
        ...x,
        open: x.id === secteur.id ? !x.open : false,
      }));
      this[this.mercateamSectors ? "merca_perimeters" : "overloadedPerimetres"][
        level.collection
      ] = newSecteurs;
    },

    onItemSelected(secteur: any, level: any, field: any = {}, remove = false) {
      this.onChange(secteur, level, field, remove);

      if (this.singleSelect) {
        const secteurs = _.get(this.computedPerimetres, level.collection, []);
        const newSecteurs = secteurs.map((x: any) =>
          x.id === secteur.id ? {...x, selected: !x.selected} : x,
        );
        this[
          this.mercateamSectors ? "merca_perimeters" : "overloadedPerimetres"
        ][level.collection] = newSecteurs;
      }
      if (!this.keepOpen) this.closeDropdown();
    },

    closeDropdown() {
      this.$emit("close");
    },

    onConfirmDropdown(): void {
      this.onConfirm();
      this.closeDropdown();
    },

    onSearchChange: _.debounce(function (this: any, val: string) {
      if (!val) return;
      this.overloadPerimetres(this.perimeters);
    }, 1000),
    filterOnSearch(
      array: unknown[],
      search: string = this.search,
      idx,
      level,
      overloadedPerimetres,
    ) {
      return array.filter((x: any) => {
        //either the name matches
        if (searchSubstring(x?.name, search)) return true;
        //Or the entry has children that match
        let match = false;

        for (let i = 0; i < idx; i++) {
          const sublevel = reverseLevelCorresp[i];

          match =
            match ||
            overloadedPerimetres[sublevel.collection].some(
              (y: any) => y[level.type + "_id"] === x.id,
            );
        }

        return match;
      });
    },

    sectorHasChildSelected(sector: any) {
      return this.cleanSelectedSectors.some(
        (sect: any) => sect[sector.type + "_id"] === sector.id,
      );
    },

    isSelectedGroup(group: SectorGroup): boolean {
      return this.cleanSelectedSectors.some(({id}) => id === group.id);
    },

    selectSectorGroup(group: SectorGroup): void {
      const removeSectorsFromSelection = this.isSelectedGroup(group);

      if (this.onChangeMultiple)
        this.onChangeMultiple(group, removeSectorsFromSelection);
      else {
        group.sectors.forEach((sector) => {
          this.onChange(
            {id: sector.secteur_id},
            null,
            null,
            removeSectorsFromSelection,
          );
        });
      }
    },
  },
});
</script>
<style scoped lang="scss">
.spread {
  height: max-content;
  max-height: 300px;
  overflow-y: scroll;
  border: solid 1px rgb(var(--v-theme-newSelected));
  border-radius: 8px;
}

.dropdown-list--perimeters {
  max-height: max(30vh, 300px);
}
</style>
