import _ from "lodash";
import moment from "moment";
import {defineStore, storeToRefs} from "pinia";
import {i18n} from "@/i18n";
import {useDBUtils} from "@/composables/dbUtils";
import {useMainStore} from "@/stores/mainStore";
import {useSimulationStore} from "@/stores/simulationStore";
import {getImportantKeys} from "@/tscript/utils";
import {saveEventInFirestore} from "@/tscript/utils/eventSaver";
import {
  DATE_DEFAULT_FORMAT,
  asyncForEach,
  datesFromPeriod,
  getOperatorsKeys,
} from "@oplit/shared-module";
import {
  Action,
  CleanOplitEvent,
  Machine,
  MSDField,
  Operator,
  OplitEventImpact,
  OplitEventModification,
  OplitEventModificationType,
  OplitPlanningEventChild,
  PlanningOperatorPeriod,
  Qualification,
  Sector,
  OplitPlanningEvent,
  OplitEventChild,
  Poste,
  CustomPeriod,
  Segment,
  FullAggregatedPdpLoadEvent,
} from "@/interfaces";
import {EVENT_STATUS, QUALIFICATIONS_PLANNING_ACTION} from "@/config/constants";
import {serverTimestamp} from "firebase/firestore";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {useParametersStore} from "@/domains/parameters/stores/parametersStore";
import {inject, unref, ref} from "vue";
import {getSegmentPayload} from "@/tscript/utils/segmentHelper";
import {oplitClient} from "@/api";

export const useEventsStore = defineStore("events", () => {
  const segment = inject<Segment>("segment");

  const {t} = i18n;
  const {getOplitFirestoreDocumentUserMetadata} = useDBUtils();
  const mainStore = useMainStore();
  const {loadClientParametersList} = useParametersStore();
  const {saveSSEAndListen} = useSimulationStore();
  const selectedEvent = ref<CleanOplitEvent | FullAggregatedPdpLoadEvent>(null);

  function shouldUseEventSSE(event: CleanOplitEvent) {
    const {pgSse} = storeToRefs(mainStore);
    // planning events are saved with the `capa` type
    if (event.is_planning_event) return pgSse.value.includes("planning");

    return pgSse.value.includes(event.type);
  }

  function getEventMetadata(
    event: Partial<CleanOplitEvent>,
    isNewEvent: boolean,
  ) {
    return {
      ...getOplitFirestoreDocumentUserMetadata(event),
      modifications: [
        ...(event.modifications || []),
        getEventModification(isNewEvent ? "creation" : "modification"),
      ],
    };
  }

  // returns an event modification object
  function getEventModification(
    type: OplitEventModificationType,
  ): OplitEventModification {
    const {userData} = storeToRefs(mainStore);
    const now = moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS");
    const {id: user_id, first_name, last_name} = userData.value;

    return {
      type,
      first_name,
      last_name,
      user_id,
      timestamp: now,
    };
  }

  function getBaseSiteEvent() {
    const {activePerim} = storeToRefs(mainStore);
    const {
      site_id,
      site_name,
      id: sector_id,
      level: sector_level,
    } = activePerim.value;

    return {
      msd_secteur_id: sector_id,
      msd_level: sector_level,
      is_tag: true,
      level: 0,
      type: "site",
      collection: "sites",
      site_id,
      site_name,
    };
  }

  async function getEventQualificationFromSerie(
    serie: string,
  ): Promise<Qualification> {
    // TODO: this should be set elsewhere
    const [, {fields = []}] = await loadClientParametersList();

    // returns the label & icon for this field within the sector parametres_msd fields
    const {label, icon = "file"} =
      fields.find((field: MSDField): boolean => field?.model === serie) || {};
    let field = label;
    if (["absences", "arrets"].includes(serie)) field = "planning";
    return {
      text: _.capitalize(
        t("Qualifications.Capa.modification", {
          field,
        }) as string,
      ),
      serie,
      icon,
    };
  }

  function getBasePlanningEvent(
    event: Partial<CleanOplitEvent>,
    isNewEvent = true,
  ): CleanOplitEvent {
    const {userData, simulation, activePerim} = storeToRefs(mainStore);
    const metadata = getEventMetadata(event, isNewEvent);

    const unit =
      activePerim.value.unite ||
      activePerim.value.unit ||
      (t("Commons.pieces") as string);

    return {
      id: event.id || dbHelper.getCollectionId("events"),
      title: event.title, // || this.getEventAutogeneratedTitle(event),
      periode: event.periode ?? [],
      simulation_id: simulation.value?.id || null,
      type: "capa",
      status: "active",
      impact: {value: 0},
      actions: Array.from(
        event.actions || [],
        (action: Action): Action => ({
          ...action,
          event_id: event.id,
        }),
      ),
      is_planning_event: true,
      client_id: userData.value.client_id,
      unit,
      ...metadata,
    };
  }

  async function getOperatorAssignmentPlanningEvent(payload: {
    event: CleanOplitEvent;
    operators: Operator[];
    machine: Machine;
    isNewEvent: boolean;
  }) {
    const {event, operators, machine} = payload;
    const {id: secteur_id, name: secteur_name} = machine;

    const baseSite = getBaseSiteEvent();

    const baseEvent = getBasePlanningEvent(event);

    //we create a fake sector under the site directly
    const sector = {
      ...baseSite,
      is_machine: true,
      secteur_id,
      secteur_name,
    };

    const {periode, unit} = baseEvent;

    const initial = getImportantKeys(sector);
    const child = {
      periode,
      unit,
      secteur_id,
      secteur_name,
      initial,
      associates: [initial],
      operateurs: operators.map(getOperatorsKeys),
    };

    const newEvent = {
      ...baseEvent,
      children: [child],
      qualification: await getEventQualificationFromSerie("nb_equipe"),
    };

    return newEvent;
  }

  async function getOperatorAbsencePlanningEvent(payload: {
    event: Partial<CleanOplitEvent>;
    operators: Operator[];
  }): Promise<OplitPlanningEvent> {
    const {event = {} as CleanOplitEvent, operators} = payload;
    const baseSite = getBaseSiteEvent();

    const baseEvent = getBasePlanningEvent(event);

    const {unit} = baseEvent;

    const children: OplitPlanningEventChild[] = operators
      // filters unconfigured child
      .filter(({operator_id}: {operator_id: string}) => !!operator_id)
      .map((operator: PlanningOperatorPeriod) => {
        const {
          operator_id: secteur_id,
          operator_name: secteur_name,
          startDate,
          /**
           * we need to have the `periode` with a startDate and endDate in the back
           * we default the endDate as the startDate if the former is not defined
           */
          endDate = startDate,
        } = operator;

        //we create a fake sector (representing the operator) under the site directly
        const sector = {
          ...baseSite,
          is_operator: true,
          secteur_id,
          secteur_name,
        };

        const initial = getImportantKeys(sector);

        const child = {
          periode: [startDate, endDate],
          unit,
          secteur_id,
          secteur_name,
          initial,
          associates: [initial],
          absences: 1,
        };

        return child;
      });

    const title =
      operators.length > 1
        ? t("Qualifications.AutogeneratedTitles.absences", {
            count: operators.length,
          })
        : t("Qualifications.AutogeneratedTitles.absence", {
            operator: operators[0].name,
          });

    const newEvent: OplitPlanningEvent = {
      ...baseEvent,
      title: baseEvent.title ?? title, // keep baseEvent's title if defined manually
      qualification: await getEventQualificationFromSerie("absences"),
      is_operator: true,
      children,
    };

    return newEvent;
  }

  async function saveEvent(payload: {
    event: CleanOplitEvent;
    isNew: boolean;
    callback: () => unknown;
    closingCallback: () => unknown;
    params: Record<string, unknown>;
  }): Promise<void> {
    const {disablePgRefresh} = storeToRefs(mainStore);
    const {event, isNew, callback, closingCallback, params} = payload;
    const {nb_events, is_last_event} = params ?? {};

    const shouldErasePreviousMacroEvent = !isNew;

    await saveEventInFirestore(event as unknown as Event, {
      use_event_sse: shouldUseEventSSE(event),
      shouldErasePreviousMacroEvent,
      fullEvent: event,
      disable_pg_refresh: disablePgRefresh.value,
      storeCommitCallback: () => (disablePgRefresh.value = false),
      sseCallback: (data: Event) =>
        saveSSEAndListen({
          extraParams: {oldEvent: event},
          noListening: nb_events && !is_last_event,
          dataType: shouldErasePreviousMacroEvent
            ? EVENT_STATUS.UPDATED
            : EVENT_STATUS.ADDED,
          routeName: "Event",
          data,
          callback,
          closingCallback,
        }),
    });
  }

  async function loadSimpleEvents(payload: {parsedPeriod: CustomPeriod}) {
    try {
      const {sectorTree, simulation, stations} = storeToRefs(mainStore);
      const {id: simulation_id} = simulation.value || {};
      // the parsedPeriod is arbitrary, adapt at will
      const {
        parsedPeriod = {
          startDate: moment().startOf("month").format(DATE_DEFAULT_FORMAT),
          endDate: moment().endOf("month").format(DATE_DEFAULT_FORMAT),
        },
      } = payload || {};

      // array of stations related to the current sector
      const relatedPostes = stations.value.filter((poste: Poste) =>
        [...poste.idsPath, poste.id].includes(sectorTree.value.id),
      );

      // array of ids corresponding to the parent/children of this sector
      // passed as parameters for the query to return unexpected event of related sectors
      const sectorIDs: string[] = _.uniq(
        Array.from(relatedPostes, (poste: Poste): string[] => [
          ...poste.idsPath,
          poste.id,
        ]).flat(),
      );

      const [simpleEvents, simpleEventsError] =
        await oplitClient.apiEventsGetSimpleEventsPost({
          simulation_id,
          ...(parsedPeriod as CustomPeriod & {endDate: string}),
          sector: unref(sectorTree),
          sector_ids: sectorIDs,
        });

      if (simpleEventsError) throw simpleEventsError;

      return [null, simpleEvents];
    } catch (error) {
      return [error, null];
    }
  }

  async function saveSimpleEvent(payload: {
    event: CleanOplitEvent;
    isNew: boolean;
  }): Promise<unknown> {
    const {userData, simulation, activePerim, disablePgRefresh} =
      storeToRefs(mainStore);
    const {event, isNew} = payload;

    const [child = activePerim.value] = event.children;
    const periode = (child as OplitEventChild)?.periode || event.periode || [];

    Object.assign(event, {
      ...getEventMetadata(event, isNew),
      client_id: userData.value.client_id,
      simulation_id: simulation.value?.id || null,
      status: EVENT_STATUS.ACTIVE,
      unit:
        (child as Sector).unite ||
        (child as Sector).unit ||
        t("Commons.pieces"),
      type: "capa",
      periode,
    });
    const savedEvent = {
      ...event,
      children: [child],
    };

    const result = await saveEventInFirestore(savedEvent as unknown as Event, {
      stopAfterEventSave: true,
      storeCommitCallback: () => (disablePgRefresh.value = false),
    });

    segment.value.track(
      "[PDP] Unplanned Event Created",
      getSegmentPayload("event", savedEvent),
    );

    return result;
  }

  // TODO: harmonize logics with eponymous method in simulationStore
  async function deleteEvent(payload: {
    event: CleanOplitEvent;
    callback: (...args) => unknown;
  }): Promise<unknown> {
    const {disablePgRefresh} = storeToRefs(mainStore);
    const {event, callback} = payload;

    const update = {
      id: event.id,
      status: EVENT_STATUS.REMOVED,
      updated_at: serverTimestamp(),
      pg_parsed: null,
    };

    Object.assign(event, update);

    const result = await saveEventInFirestore(update, {
      use_event_sse: shouldUseEventSSE(event),
      disable_pg_refresh: disablePgRefresh.value,
      fullEvent: event,
      storeCommitCallback: () => (disablePgRefresh.value = false),
      sseCallback: () =>
        saveSSEAndListen({
          data: event,
          dataType: EVENT_STATUS.REMOVED,
          callback,
        }),
    });

    segment.value.track(
      "[PDP] Event Deleted",
      getSegmentPayload("event", event as unknown as Record<string, unknown>),
    );

    return result;
  }

  async function getEventImpact(payload: {
    simulation_id: string;
    sector_tree: any;
    event: CleanOplitEvent;
    fields: any;
  }): Promise<OplitEventImpact> {
    const {userData, apiClient} = storeToRefs(mainStore);
    const {
      simulation_id,
      sector_tree,
      event = {} as CleanOplitEvent,
      fields = [],
    } = payload;
    const {children = []} = event;
    const secteur_id = sector_tree?.id || sector_tree?.secteur_id;
    if (!userData.value.client_id || !simulation_id || !secteur_id) return;

    let minDate: string, maxDate: string;
    const results: OplitEventImpact = {children: [], value: 0, daily: 0};
    await asyncForEach(children, async (child: any, idx: number) => {
      const {periode, datelancement, secteur_id, secteur_name, initial} = child;
      const {startDate, endDate} = datesFromPeriod(periode || datelancement);

      minDate = _.min([minDate, startDate]);
      maxDate = _.max([maxDate, endDate]);

      const cleanFields = (fields || [])
        .filter(
          (f: any) =>
            f &&
            (f.in_formula || ["capa_validee"].includes(f.model)) &&
            child[f.model],
        )
        .reduce((acc: any, f: any) => {
          let value = +child[f.model];
          if (f.suffix === "%" && value > 1) value = _.round(value / 100, 2);
          if (f.type === "dropdown") {
            const values = _.get(child, [f.model, "values"]) || [];
            values.forEach((subvalue: any, idx: number) => {
              acc.push({
                field: f.model,
                value: subvalue,
                event_weekday: idx + 1,
              });
            });
          } else acc.push({field: f.model, value});
          return acc;
        }, []);

      if (!cleanFields.length) {
        _.set(results.children, idx, 0);
        return;
      }

      const params = {
        query_type: "capa",
        client_id: userData.value.client_id,
        sector: {
          id: secteur_id,
          secteur_name,
          type: initial?.type,
        },
        startDate,
        endDate,
        simulation_id,
        custom_fields: cleanFields,
        load_auto_orga: true,
      };
      const promises = [
        apiClient.value.getCustomCapa(params),
        oplitClient.apiCalendarGetWorkdaysPost(params),
      ];

      const [capaResult, [days]] = await Promise.all(promises);
      const {capa = 0, msd_capa = 0} = capaResult || {};

      const value = _(capa - (msd_capa ?? capa)).round(1);
      const workdays = _.sumBy(days, "workdays") || 0;
      _.set(results.children, idx, {
        value,
        daily: value / workdays,
      });
    });

    const totalValue = _.sum(results.children.map((child: any) => child.value));

    const [totalDays] = await oplitClient.apiCalendarGetWorkdaysPost({
      sector: sector_tree,
      startDate: minDate,
      endDate: maxDate,
    });
    const totalWorkdays = _.sumBy(totalDays, "workdays") || 1;
    results.value = totalValue ?? null;
    results.daily = totalValue / totalWorkdays || 0;
    return results;
  }

  // FIXME: refactor this in a clearner way
  function getEventDefaultChildren(
    initial: Sector,
    checkedItems: any[],
    sector: Sector,
    period: string[],
    isSimpleEvent = false,
  ) {
    const {sectorTree} = storeToRefs(mainStore);
    // this is in conjunction with capaLoadEvent/blocksOrSelected
    // when returning an empty array, the first condition is false and therefore we use computedBlocks
    if (checkedItems?.length) return [];
    const unit = sectorTree.value?.unite || sectorTree.value?.unit || "p";

    // being indicative, the unplanned (= simple) events only need a subset of the information required in the other EventModals
    if (isSimpleEvent) return [{initial: {...getImportantKeys(sector), unit}}];

    type Child = {
      isNew: boolean;
      secteur_id: string | null;
      secteur_name: string | null;
      initial: Sector;
      associates: Sector[];
      unit: string;
      periode?: string[];
    };

    const child: Child = {
      isNew: true,
      secteur_id: sector.id || null,
      secteur_name: sector.name || null,
      initial,
      associates: [initial],
      unit,
    };

    if (period?.length) child.periode = period;

    return [child];
  }

  function getPlanningEventQualification(event: OplitPlanningEvent) {
    const {children = [{}]} = event;
    const {arrets, absences} = children.at(0);
    const isAbsenceOrArret = arrets || absences;

    return {
      action: !isAbsenceOrArret
        ? QUALIFICATIONS_PLANNING_ACTION.ASSIGNMENT
        : arrets
        ? QUALIFICATIONS_PLANNING_ACTION.MACHINE_STOP
        : QUALIFICATIONS_PLANNING_ACTION.ABSENCE,
    };
  }

  function getPlanningEventTitle(
    action: string,
    entity: Operator | Machine,
  ): string {
    switch (action) {
      /**
       * values that have been kept aside from constants are so because they do not have
       * specific logic attached to it : they are only meant to have a specific title when :
       * - REMOVING_OPERATOR : removing an operator (qualification is QUALIFICATIONS_PLANNING_ACTION.ASSIGNMENT)
       * - NEW_ASSIGNMENT_PERCENTAGE : setting a new assignment percentage (qualification is QUALIFICATIONS_PLANNING_ACTION.ASSIGNMENT)
       */

      case "REMOVING_OPERATOR":
        return t("Qualifications.AutogeneratedTitles.removing_operator", {
          machine: entity.name,
          operator: entity.assignment_name,
        });

      case "NEW_ASSIGNMENT_PERCENTAGE":
        return t(
          "Qualifications.AutogeneratedTitles.new_assignment_percentage",
          {
            value: entity.assignment_percentage,
            operator: entity.name,
            machine: entity.assignment_name,
          },
        );

      case QUALIFICATIONS_PLANNING_ACTION.ASSIGNMENT:
        return t("Qualifications.AutogeneratedTitles.affectations", {
          entity: entity.name,
        });

      case QUALIFICATIONS_PLANNING_ACTION.ABSENCE:
        return t("Qualifications.AutogeneratedTitles.absence", {
          operator: entity.name,
        });
      case QUALIFICATIONS_PLANNING_ACTION.MACHINE_STOP:
        return t("Qualifications.AutogeneratedTitles.arret", {
          machine: entity.name,
        });
      case QUALIFICATIONS_PLANNING_ACTION.MACHINE_ADD:
        return `${_.capitalize(
          t("Planning.addition", {item: "machine"}) as string,
        )} : ${entity.name}`;
      default:
        break;
    }
  }

  return {
    deleteEvent,
    getEventMetadata,
    getBaseSiteEvent,
    getEventQualificationFromSerie,
    getBasePlanningEvent,
    getOperatorAssignmentPlanningEvent,
    getOperatorAbsencePlanningEvent,
    saveEvent,
    saveSimpleEvent,
    getEventImpact,
    getEventModification,
    shouldUseEventSSE,
    getEventDefaultChildren,
    getPlanningEventQualification,
    getPlanningEventTitle,
    loadSimpleEvents,
    selectedEvent,
  };
});
