<template>
  <div :class="{'is-synthetic': isSyntheticView}" class="gantt-row-prefix">
    <div class="gantt-row-prefix-calendar__entity-name-wrapper">
      <div class="gantt-row-prefix-calendar__entity-wrapper">
        <div
          class="gantt-row-prefix-calendar__entity-name"
          :class="{full: !entity.operations?.length}"
        >
          <div class="gantt-row-prefix-calendar__entity-name-content">
            <div class="gantt-row-prefix-calendar__entity-name-content-first">
              <OplitIcon
                name="hexagon"
                :stroke="variables['grey-700']"
                size="16px"
              />

              <span
                v-tooltip="displayedName"
                class="gantt-row-prefix-calendar__sector-name text-ellipsis"
              >
                {{ displayedName }}
              </span>

              <span
                v-if="ofsCount > 0"
                class="text-grey-500 text-14 text-no-wrap"
              >
                ({{ ofsCount }} {{ $t("Commons.ofs", ofsCount) }})
              </span>

              <CapaTooltip
                :class="{
                  'bg-red-100 text-red-800': averageLoadRate.isOverloaded,
                }"
                :capa-percentage="averageLoadRate.capa_percentage"
                :computed-charge="averageLoadRate.load"
                :charge-table="averageLoadRate.loadDetail"
                :computed-capa="averageLoadRate.capa"
                :unit="averageLoadRate.unit"
                class="gantt-row-prefix-calendar__load-rate"
              />

              <v-progress-circular
                v-if="loading"
                size="12"
                width="2"
                class="ml-auto"
                :color="variables.newSelected"
                indeterminate
              />

              <vue-feather
                v-else-if="entity.operations?.length"
                :type="showOperations ? 'chevron-up' : 'chevron-down'"
                class="cursor-pointer ml-auto"
                style="flex: 0 0 24px"
                @click="() => $emit('toggle-operations', getEntityKey(entity))"
              />
            </div>

            <DevHelper mini absolute> ({{ entity.secteur_id }}) </DevHelper>

            <div
              v-if="!isSyntheticView"
              :class="{'justify-end': hideProgressRate}"
              class="gantt-row-prefix-calendar__entity-name-content-second"
            >
              <template v-if="!hideProgressRate">
                <EntityProgressRate
                  :current="progressRate.produced"
                  :total="progressRate.qte"
                  :unit="progressRate.unit"
                  :entity="entity"
                  :period="ganttPeriod"
                  bg-color-name="grey-100"
                  class="flex-1"
                  hide-entity-name
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import {useI18n} from "vue-i18n";
import {EntityProgressRate} from "@/components/Commons";
import CapaTooltip from "@/components/Scheduling/Capacity/CapaTooltip.vue";
import {useGanttStore} from "@/domains/scheduling/domains/gantt/stores/ganttStore";
import {useMainStore} from "@/stores/mainStore";
import {getReadableImportParsingRuleValue} from "@oplit/shared-module";
import {prettifyNumber} from "@/tscript/utils/generalHelpers";
import {getOperationProgressRateItems} from "@/tscript/utils/schedulingUtils";
import type {
  DailyLoadRatesObject,
  SchedulingOperation,
  SectorLike,
  Sector,
} from "@/interfaces";

interface GanttRowPrefixCalendarProps {
  entity: SectorLike & {operations: SchedulingOperation[]};
  dailyLoadRates: DailyLoadRatesObject;
  hideProgressRate?: boolean;
  loading?: boolean;
  isSyntheticView?: boolean;
  showOperations?: boolean;
}

const props = withDefaults(defineProps<GanttRowPrefixCalendarProps>(), {
  entity: () => ({} as GanttRowPrefixCalendarProps["entity"]),
  hideProgressRate: false,
  loading: false,
  isSyntheticView: false,
  showOperations: false,
});

defineEmits<{
  (e: "toggle-operations", id: string);
}>();

const {ganttPeriod, ganttDaysArray} = storeToRefs(useGanttStore());

const mainStore = useMainStore();
const {variables} = storeToRefs(mainStore);

const {t} = useI18n();

const averageLoadRate = computed<{
  load: number;
  loadDetail: {of_id: string; load: number}[];
  capa: number;
  unit?: string;
  capa_percentage: string;
  isOverloaded: boolean;
}>(() => {
  const {dailyLoadRates} = props;

  const totalLoad = ganttDaysArray.value.reduce(
    (acc, day) => acc + (dailyLoadRates?.[day]?.load || 0),
    0,
  );
  const totalCapa = ganttDaysArray.value.reduce(
    (acc, day) => acc + (dailyLoadRates?.[day]?.capa || 0),
    0,
  );

  const totalLoadDetail = ganttDaysArray.value.reduce((acc, day) => {
    const {mappedOpsWithDailyLoad = []} = dailyLoadRates?.[day] || {};

    for (const {of_id, load} of mappedOpsWithDailyLoad)
      acc.set(of_id, (acc.get(of_id) || 0) + load);

    return acc;
  }, new Map());

  const loadRatesArray = ganttDaysArray.value.reduce((acc, day) => {
    const loadRate = dailyLoadRates?.[day]?.value;
    // closed day: we don't take into account for load rate determination
    if (loadRate != null) acc.push(loadRate);

    return acc;
  }, []);

  const averageValue = prettifyNumber(_.mean(loadRatesArray), "0");

  const unit = dailyLoadRates?.[ganttDaysArray.value[0]]?.unit;

  const capaPercentage =
    totalLoad && !totalCapa ? t("global.infinity") : `${averageValue || 0}%`;

  const isOverloaded = totalCapa && totalLoad > totalCapa;

  return {
    load: totalLoad,
    loadDetail: Array.from(totalLoadDetail, ([of_id, load]) => ({
      of_id,
      load,
    })).sort((a, b) => b.load - a.load),
    capa: totalCapa,
    unit,
    capa_percentage: capaPercentage,
    isOverloaded,
  };
});

const ofsCount = computed(() => {
  if (!props.entity.operations?.length) return 0;
  return Object.keys(_.groupBy(props.entity.operations, "of_id")).length;
});

const progressRate = computed<{produced: number; qte: number; unit: string}>(
  () => getOperationProgressRateItems(props.entity.operations),
);

const displayedName = computed<string>(() =>
  getReadableImportParsingRuleValue(props.entity.secteur_name),
);

function getEntityKey(entity: SectorLike): string {
  return (entity as Sector).secteur_id;
}
</script>

<style scoped lang="scss">
.gantt-row-prefix {
  --y-padding: 12px;

  position: sticky;
  left: 0;
  display: flex;
  border: var(--gantt-border);
  border-top: none;
  background: inherit;
  z-index: calc(var(--gantt-z-index--operation) + 1);

  & > div:not(:last-child) {
    border-right: var(--gantt-border);
  }

  & .gantt-row-prefix-calendar__entity-name-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(var(--gantt-entity-name-width) - 1px);
    box-shadow: 4px 0px 8px 0px rgba(15, 15, 15, 0.04);

    :deep(.gantt-row-prefix-calendar__load-rate) {
      background: var(--o-black-opacity-50);
      padding: 4px;
      border-radius: 4px;
      font-size: 12px;
      line-height: 16px;
      color: rgb(var(--v-theme-grey-700));
    }

    & .gantt-row-prefix-calendar__entity-wrapper {
      position: sticky;
      top: var(--gantt-total-header-height);
      display: flex;
      width: var(--gantt-prefix-width);
      height: 100%;
      padding: 4px 8px;
      background: rgb(var(--v-theme-grey-50));
      border-right: var(--gantt-border);
    }

    & .gantt-row-prefix-calendar__entity-name {
      display: flex;
      align-items: center;
      gap: 16px;
      flex: 1;
      width: calc(var(--gantt-entity-name-width) - 70px);
      &.full {
        width: calc(var(--gantt-entity-name-width) - 32px);
      }

      & i {
        flex: var(--gantt-select-icon-flex);
      }

      & .gantt-row-prefix-calendar__entity-name-content {
        flex: 1;
        min-width: 0;

        & .gantt-row-prefix-calendar__entity-name-content-first {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        & .gantt-row-prefix-calendar__entity-name-content-second {
          display: flex;
          align-items: center;
          gap: 16px;
        }

        & .gantt-row-prefix-calendar__sector-name {
          cursor: pointer;
          color: rgb(var(--v-theme-grey-700));
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  &.is-synthetic {
    & > div {
      padding: 6px 12px;
    }

    &
      .gantt-row-prefix-calendar__entity-name
      .gantt-row-prefix-calendar__entity-name-content
      strong {
      font-size: 16px;
    }
  }
}

:deep(.entity-progress-rate--value) {
  font-size: 12px;
  line-height: 16px;
  color: rgb(var(--v-theme-grey-500));
}
</style>
