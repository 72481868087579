import {computed, MaybeRef, toValue} from "vue";
import {storeToRefs} from "pinia";

import {
  getOperationStatus,
  OF_STATUS,
  PENDING_ORDERS_PARAMS,
  SchedulingOperation,
} from "@oplit/shared-module";
import {useMainStore} from "@/stores/mainStore";
import {useI18n} from "vue-i18n";

export function useOperationStatus(operation?: MaybeRef<SchedulingOperation>) {
  const {t} = useI18n();
  const mainStore = useMainStore();
  const {clientParameters} = storeToRefs(mainStore);

  const displayedOperationStatus = computed(() => {
    const computedStatus = displayedOperationStatusLabel.value;
    if ((computedStatus + "").includes("operation"))
      return {text: "operation", append: computedStatus.split(" ")?.[1]};
    return {text: computedStatus};
  });

  const baseStatuses = computed(() =>
    Object.values(OF_STATUS).map((s) => ({
      text: t(`operation.status.${s}`),
      value: s,
    })),
  );

  const pendingOrderStatuses = computed(() => [
    ...Array.from({length: PENDING_ORDERS_PARAMS.COUNT_LIMIT + 1}, (_, s) => ({
      text: `${t(`operation.status.operation`)} N-${s}`,
      value: s,
    })),
    {
      text: t(`operation.status.operations_above`, {
        count: PENDING_ORDERS_PARAMS.COUNT_LIMIT,
      }),
      value: PENDING_ORDERS_PARAMS.OTHERS,
    },
  ]);

  const displayedOperationStatusLabel = computed(() => {
    const op = toValue(operation);
    const pending_order =
      op.internal_extra_infos?.pending_order ?? op.pending_order;
    let computedStatus = getOperationStatus(op);
    if (
      toValue(clientParameters).has_ordered_pending_op_status &&
      pending_order != null &&
      (computedStatus === OF_STATUS.PENDING || computedStatus === OF_STATUS.POF)
    )
      computedStatus = `operation N-${pending_order}`;

    return computedStatus;
  });

  return {
    displayedOperationStatus,
    baseStatuses,
    pendingOrderStatuses,
    displayedOperationStatusLabel,
  };
}
