<script setup lang="ts">
import {computed} from "vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";

type FavoritesParametersKeys = "favoritesSectors" | "favoritesSectorsGroups";

/**
 * @parametersKey key in the collection `parametres_user` where the value (`entityId`) is stored in a strings array
 * @entityId string value stored in the array
 */
interface FavoriteWrapperProps {
  parametersKey: FavoritesParametersKeys;
  entityId: string;
  centered?: boolean;
}

const props = defineProps<FavoriteWrapperProps>();

const {userParameters} = storeToRefs(useMainStore());
const {setUserParameter} = useMainStore();

const currentUserParameter = computed<string[]>(
  () => userParameters.value[props.parametersKey] || [],
);
const isFavorite = computed<boolean>(() =>
  currentUserParameter.value.includes(props.entityId),
);

function setFavorite(): void {
  if (isFavorite.value) {
    setUserParameter({
      [props.parametersKey]: currentUserParameter.value.filter(
        (id) => id !== props.entityId,
      ),
    });
  } else {
    setUserParameter({
      [props.parametersKey]: [...currentUserParameter.value, props.entityId],
    });
  }
}
</script>

<template>
  <div
    :class="{
      'favorite-wrapper__is-favorite': isFavorite,
      'items-center': centered,
    }"
    class="favorite-wrapper"
  >
    <span class="text-ellipsis">
      <slot />
    </span>

    <OplitIcon name="star" @click.stop="() => setFavorite()" />
  </div>
</template>

<style scoped lang="scss">
.favorite-wrapper {
  --star-color: rgb(var(--v-theme-yellow-300));

  display: flex;
  gap: 8px;

  & svg {
    cursor: pointer;
  }

  // properly aligning default slot content and icon when alignment is start
  &:not(.items-center) svg {
    margin-top: 3px;
  }

  &:not(.favorite-wrapper__is-favorite) {
    & svg {
      opacity: 0;
    }

    &:hover svg {
      opacity: 1;
      fill: white;
      stroke: rgb(var(--v-theme-grey-700));

      &:hover {
        fill: var(--star-color);
        stroke: var(--star-color);
      }
    }
  }

  &.favorite-wrapper__is-favorite {
    fill: var(--star-color);
    stroke: var(--star-color);
  }
}
</style>
