<template>
  <div class="gantt-header--wrapper">
    <div class="gantt-header text-14">
      <div
        v-if="ganttMesh !== SCHEDULING_GANTT_MESHES_OPTIONS.week"
        class="gantt-header--meshes-wrapper"
      >
        <div
          v-for="(mesh, monthIndex) in displayedMeshes"
          :key="mesh"
          :style="getMeshStyle(mesh, monthIndex)"
          :class="{
            'is-first-mesh-displayed': isFirstMeshDisplayed(mesh, monthIndex),
          }"
        >
          {{ mesh }}
        </div>
      </div>

      <div class="gantt-header--days-wrapper">
        <div
          v-for="(day, index) in ganttDaysArray"
          :key="`day-${day}`"
          :class="[
            {'is-last-week-day': isLastWeekDay(day)},
            'gantt-header--day-wrapper',
          ]"
        >
          <div
            v-define-color="{
              day_date: day,
              disabled: getGanttIsPastDelayShown && !index,
            }"
            :class="CSS_SCHEDULING_GANTT_CELL_CLASS"
            :style="getDayStyle(day)"
            class="gantt-header--day"
          >
            {{ getDisplayedDayValue(day, index) }}
          </div>
          <div
            v-if="ganttMesh === SCHEDULING_GANTT_MESHES_OPTIONS.week"
            :class="{'bg-newLightGrey': isClosedDay(day)}"
            class="gantt-header--segments"
          >
            <v-tooltip
              v-for="segment in daySegments"
              :key="`segment-${segment.label}`"
              location="top"
            >
              <template v-slot:activator="{props}">
                <div v-bind="props">
                  {{ segment.label }}
                </div>
              </template>

              {{ segment.tooltip }}
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import {
  CSS_SCHEDULING_GANTT_CELL_CLASS,
  SCHEDULING_GANTT_MESHES_OPTIONS,
  SCHEDULING_GANTT_SHIFTS_OPTIONS,
} from "@/config/constants";
import type {HTMLElementStyleObject} from "@/interfaces";
import moment from "moment";
import {useGanttStore} from "@/domains/scheduling/domains/gantt/stores/ganttStore";

import {useMainStore} from "@/stores/mainStore";
import {isWorkDay} from "@oplit/shared-module";

// FIXME
// const format = "H:mm";
const monthFormat = "MMMM YYYY";
const defaultShifts = {
  morning: "6:00",
  afternoon: "14:00",
  night: "22:00",
};

const defaultHours = {
  morning: "0:00",
  afternoon: "8:00",
  night: "16:00",
};

export default defineComponent({
  name: "gantt-header",
  props: {
    diagramLeftScroll: {type: Number, default: 0},
  },
  setup() {
    const getGanttIsPastDelayShown = inject("getGanttIsPastDelayShown");

    const {
      ganttMesh,
      ganttHideWeekend,
      ganttCellWidth,
      ganttDaysArray,
      ganttDaysMomentFormat,
    } = storeToRefs(useGanttStore());
    const {getDailyColorCssVar} = useGanttStore();

    const mainStore = useMainStore();
    const {clientParameters, variables} = storeToRefs(mainStore);
    const {getCtxSectorOrderedCalendars} = mainStore;

    function isClosedDay(day: string) {
      return !isWorkDay(day, getCtxSectorOrderedCalendars());
    }

    return {
      SCHEDULING_GANTT_MESHES_OPTIONS,
      SCHEDULING_GANTT_SHIFTS_OPTIONS,
      ganttMesh,
      ganttHideWeekend,
      getGanttIsPastDelayShown,
      ganttCellWidth,
      ganttDaysArray,
      ganttDaysMomentFormat,
      clientParameters,
      isClosedDay,
      variables,
      CSS_SCHEDULING_GANTT_CELL_CLASS,
      getDailyColorCssVar,
    };
  },
  computed: {
    displayedMeshes(): string[] {
      const daysArray = Array.from(
        this.ganttDaysArray,
        (date: string): string => moment(date).format(monthFormat),
      );
      return Array.from(_.uniq(daysArray), _.capitalize);
    },
    daySegments() {
      const segments = this.clientParameters.has_gantt_shift_view
        ? // FIXME: replace `this.clientParameters.shifts` with correct fb object once the shift customization is available
          this.clientParameters.shifts ?? defaultShifts
        : defaultHours;

      const segmentsEntries = this.clientParameters.has_gantt_shift_view
        ? Object.entries(SCHEDULING_GANTT_SHIFTS_OPTIONS)
        : Object.entries(defaultHours);

      return Array.from(
        segmentsEntries,
        ([key, label]: [key: string, label: string], index: number) => ({
          label,
          key,
          value: segments[key],
          tooltip: [
            segments[key],
            // start hour of the next segment
            segments[segmentsEntries[(index + 1) % segmentsEntries.length][0]],
          ].join(" - "),
        }),
      );
    },
  },
  methods: {
    /**
     * this method is used to apply specific style to the displayed meshes in function of the current left scroll status of the diagram
     * upon moving far enough, the first `fixed` displayed mesh will be replaced with the new correct one
     */
    isFirstMeshDisplayed(mesh: string, index: number): boolean {
      if (this.displayedMeshes.length === 1) return true;
      const currentMonthOffset: number = this.getMeshLeftOffset(mesh);
      const nextMonthOffset: number = this.getMeshLeftOffset(
        this.displayedMeshes[index + 1],
      );
      // offset equivalent to the left padding for smoother display
      const leftValue: number = this.diagramLeftScroll + 12;
      const defaultCondition = leftValue >= currentMonthOffset;
      if (!nextMonthOffset) return defaultCondition;
      return defaultCondition && leftValue < nextMonthOffset;
    },
    getMeshLeftOffset(mesh: string): number {
      const {ganttDaysArray, ganttCellWidth} = this;

      const previousDaysLength = ganttDaysArray.filter((day: string) =>
        moment(day).isBefore(moment(mesh, monthFormat)),
      ).length;

      return previousDaysLength * ganttCellWidth;
    },
    getMeshStyle(mesh: string, index: number): HTMLElementStyleObject {
      if (this.isFirstMeshDisplayed(mesh, index)) return;
      const leftOffset = this.getMeshLeftOffset(mesh);
      /**
       * there are scenarios where the last mesh text overflows from the header
       * it occurs when we reach a new month at the end of the table for the month view
       * we check that the remaining space is enough for the display, otherwise we hide it through css
       */
      const clientWidth = this.$el?.clientWidth ?? 0;
      // this value accounts for the container & the mesh text horizontal paddings
      const xPaddingSums = 48;
      const maxWidth = clientWidth - leftOffset - xPaddingSums;
      if (maxWidth < 0) return {display: "none"};

      return {
        left: `${leftOffset}px`,
        maxWidth: `${maxWidth}px`,
        height: "1rem",
        padding: "0 16px",
        marginLeft: "-16px",
        top: "6px",
      };
    },
    isLastWeekDay(day: string): boolean {
      return moment(day).isoWeekday() === (this.ganttHideWeekend ? 5 : 7);
    },
    getDisplayedDayValue(day: string, index: number): string {
      const dayText = moment(day).format(this.ganttDaysMomentFormat);
      if (!this.getGanttIsPastDelayShown || index) return dayText;
      return `≤ ${dayText}`;
    },
    getDayStyle(day: string): Record<string, string> {
      const isPastDay = moment().isAfter(moment(day), "day");

      const colorName = isPastDay
        ? "newDisableBG"
        : this.isClosedDay(day)
        ? "newLightGrey"
        : "newLayerBackground";

      const style: Record<string, string> = {
        backgroundColor: `var(${this.getDailyColorCssVar(day)}, ${
          this.variables[colorName]
        })`,
      };

      if (isPastDay)
        Object.assign(style, {color: "rgb(var(--v-theme-newDisableText))"});

      return style;
    },
  },
});
</script>

<style lang="scss">
.gantt-header--wrapper {
  position: sticky;
  left: var(--gantt-prefix-width);
  top: 0;
  height: var(--gantt-total-header-height);
  display: inline-flex;
  margin-left: var(--gantt-prefix-width);
  z-index: calc(var(--gantt-z-index--operation-menu) + 1);
  background: rgb(var(--v-theme-newLayerBackground));

  .gantt-header {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    & .gantt-header--meshes-wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      height: var(--gantt-header-meshes-height);
      z-index: 1;

      & > div {
        padding: 6px 12px;
        line-height: normal;
        background: rgb(var(--v-theme-newLayerBackground));

        &.is-first-mesh-displayed {
          position: fixed;
          border: var(--gantt-border);
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom: none;
          width: calc(
            100% - var(--gantt-prefix-width) - var(--g-scrollbar-area) -
              var(--g-horizontal-spacing) - 68px
          );

          &::before,
          &::after {
            content: "";
            position: absolute;
            height: var(--gantt-header-height);
            border: var(--gantt-border);
            border-left: none;
            bottom: calc(
              (var(--gantt-header-height) + var(--gantt-border-width)) * -1
            );
          }

          &::before {
            left: -1px;
          }

          &::after {
            right: -1px;
          }
        }

        &:not(.is-first-mesh-displayed) {
          position: absolute;
          z-index: 1;
          margin-top: 1px;
          white-space: nowrap;
        }
      }
    }

    & .gantt-header--days-wrapper {
      display: flex;
      align-items: center;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      border-top: var(--gantt-border);

      & .gantt-header--segments {
        display: flex;
        align-items: center;
        border-bottom: var(--gantt-border);
        background: rgb(var(--v-theme-newLayerBackground));

        & > div {
          text-align: center;
          line-height: var(--gantt-shifts-line-height);
          border-right: var(--gantt-border);
          flex: 1;
        }
      }

      & .gantt-header--day-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;

        & .gantt-header--day {
          border-bottom: var(--gantt-border);
          text-align: center;
          background: rgb(var(--v-theme-newLayerBackground));
          border-right: var(--gantt-border);
        }
      }
    }
  }
}

.plan-wrapper {
  & .gantt-diagram--wrapper.is-list .gantt-header--day.is-today {
    background: rgb(var(--v-theme-newPrimaryRegular)) !important;
    color: rgb(var(--v-theme-newLayerBackground));
  }
}

.piloting-wrapper {
  & .gantt-diagram--wrapper.is-list {
    .gantt-header--day.is-today {
      background: rgb(var(--v-theme-newOrangeRegular)) !important;
      color: rgb(var(--v-theme-newLayerBackground));
    }
    .gantt-row--cell.is-today {
      background: rgb(var(--v-theme-newOrangeLight1)) !important;
    }
  }
}

// overriding the height of the ::before element when displaying the shifts
// the 1px is the bottom border width applied to their wrapping element
.gantt-diagram--wrapper.mesh-week {
  /* FIXME: why ? */
  & .gantt-row__lazy-wrapper:nth-child(2) {
    margin-top: -6px;
  }

  & .gantt-header--wrapper {
    & .gantt-header {
      margin-left: -1px;
    }

    &::before {
      content: "";
      position: sticky;
      top: inherit;
      left: var(--gantt-prefix-width);
      width: 1px;
      height: calc(
        var(--gantt-header-height) + var(--gantt-shifts-line-height) + 3px
      );
      background: var(--gantt-border-color);
      border-top-left-radius: 8px;
      z-index: 2;
    }
  }

  & .gantt-header--days-wrapper {
    // FIXME: temp for nicer display
    border-radius: 0;
  }
}

// overriding the border-radius when displaying the GanttHeaderMonth component, which displays the rounded borders
.gantt-header--wrapper
  .gantt-header
  .gantt-header--meshes-wrapper
  + .gantt-header--days-wrapper {
  border-radius: 0;
}
</style>
